import { useState, useEffect, useContext } from 'react';
import { appActions, AppContext } from 'context';
import { RouteName, goToPage } from 'routes';
import { Notification } from 'common/notification';
import { isBeta } from 'config/constants';
import { UserController } from 'networking/controllers/user-controller';

import { captureEvent } from 'posthog';
import { useParams } from 'react-router-dom';
import { Button, ButtonStyle } from 'common/button';
import { isTemporaryUser } from 'helpers/utils';
import { ModalTemporaryUser } from 'common/modal-record-email/modal-temporary-user';
import {
  BetaMessage,
  CentralSection,
  LeftSection,
  RightSection,
} from './components';
import styles from './navbar.module.scss';

const Navbar = () => {
  const { state, dispatch } = useContext(AppContext);
  const [showError, setShowError] = useState(false);
  const [showBetaMsg, setShowBetaMsg] = useState(isBeta);
  const [showLeaveTemporaryUserModal, setShowLeaveTemporaryUserModal] =
    useState(false);
  const { guestToken } = useParams<ParamsType>();

  const logOutUser = async () => {
    captureEvent('logout');
    try {
      await UserController.signOutUser();
      dispatch({ type: appActions.SESSION_EXPIRED });
      goToPage(RouteName.SignIn);
    } catch (e) {
      setShowError(true);
    }
  };

  const authenticate = async () => {
    if (state.logged) {
      return;
    }

    try {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });
    } catch (e) {
      dispatch({ type: appActions.SESSION_EXPIRED });
    }
  };

  useEffect(() => {
    authenticate();
  }, []);
  useEffect(() => {
    dispatch({ type: appActions.BETA_MSG, showBetaMsg });
  }, [showBetaMsg]);
  useEffect(() => {
    UserController.addGuestTokenHeader(guestToken);
    return UserController.removeGuestTokenHeader;
  }, [guestToken]);

  const showErrorNotification = () => (
    <Notification
      message="We couldn’t log you out. Please try again."
      handleClose={() => setShowError(false)}
    />
  );

  const showNavbarToTemporaryUsers = (user: UserType) => (
    <nav id="navbar-fixed" className={styles.container}>
      {showBetaMsg && <BetaMessage onClose={() => setShowBetaMsg(false)} />}
      <div className={styles.navbar}>
        <LeftSection user={user} />
        <div className={styles.buttonBox}>
          <Button
            buttonStyle={ButtonStyle.GreyGhost}
            onClick={() => setShowLeaveTemporaryUserModal(true)}
          >
            Leave trial
          </Button>
          <Button
            onClick={() => goToPage(RouteName.SignUp)}
            buttonStyle={ButtonStyle.PrimaryGhost}
          >
            Sign up
          </Button>
        </div>
      </div>
    </nav>
  );

  const showNavbar = (user: UserType) => (
    <nav id="navbar-fixed" className={styles.container}>
      {showBetaMsg && <BetaMessage onClose={() => setShowBetaMsg(false)} />}
      <div className={styles.navbar}>
        <LeftSection user={user} />
        <CentralSection user={user} actualSection={state.actualSection} />
        <RightSection user={user} logOutUser={logOutUser} />
      </div>
    </nav>
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showLeaveTemporaryUserModal && (
        <ModalTemporaryUser
          onClose={() => setShowLeaveTemporaryUserModal(false)}
        />
      )}
      {isTemporaryUser(state.data.user)
        ? showNavbarToTemporaryUsers(state.data.user)
        : showNavbar(state.data.user)}
    </>
  );
};

export { Navbar };
