import React, { useContext, useState } from 'react';
import { classnames } from 'helpers/utils';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { BackButton } from 'common/back-button';
import { Button, ButtonSize, ButtonType } from 'common/button';
import {
  StoreStateType,
  StoreAction,
  CheckoutSteps,
  StorePages,
} from 'context/store-context/store-reducer';
import { StoreController } from 'networking/controllers/store-controller';
import { Spinner } from 'common/spinner';
import { NotificationObject } from 'models/notificationObject';
import { AppContext, appActions } from 'context/app-context';
import { Breakpoints, NotificationType } from 'common/enums';
import {
  checkAllFieldsAreFilled,
  getMountOfItems,
  getTotalPrice,
} from 'helpers/store-helper';
import { Accordion } from 'common/accordion';
import { useMediaQuery } from 'hooks/use-media-query';
import { ConfirmOrder } from './ConfirmOrder';
import { OrderDetails } from './OrderDetails';
import { PaymentSummary } from './PaymentSummary';
import styles from './checkout.module.scss';
import { OrderList } from './OrderList';

type CheckoutProps = {
  state: StoreStateType;
  dispatch: React.Dispatch<StoreAction>;
};

export const Checkout: React.FC<CheckoutProps> = ({ state, dispatch }) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [shippingCost, setShippingCost] = useState<number | undefined>(0);
  // FOR NOW WE ARE NOT USING TAXES
  // const [shippingTax, setShippingTax] = useState<number | undefined>(0);
  const { dispatch: generalDispatch } = useContext(AppContext);

  /*   const priceWithTax = (
    shippingTax ? getTotalPrice(state) + shippingTax * getTotalPrice(state) : undefined
  ); */
  const priceWithShippingCosts = shippingCost
    ? shippingCost + getTotalPrice(state)
    : getTotalPrice(state);

  const updateShippingCosts = (stateId: string) => {
    const stateShippingCosts = state.shippingCosts.find(
      (item) => item.id === stateId,
    );
    if (stateShippingCosts) {
      setShippingCost(stateShippingCosts.cost);
      // setShippingTax(stateShippingCosts.tax);
    }
  };

  const handleBackButton = () => {
    switch (state.checkoutStep) {
      case CheckoutSteps.confirmOrder:
        dispatch({ type: 'CHANGE_PAGE', page: StorePages.selectItems });
        break;
      case CheckoutSteps.orderDetails:
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.confirmOrder,
        });
        break;
      case CheckoutSteps.paymentSummary:
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.paymentSummary,
        });
        break;
      default:
        dispatch({ type: 'CHANGE_PAGE', page: StorePages.selectItems });
    }
  };

  const handleNextButton = () => {
    switch (state.checkoutStep) {
      case CheckoutSteps.confirmOrder:
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.orderDetails,
        });
        break;
      case CheckoutSteps.orderDetails:
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.paymentSummary,
        });
        break;
      default:
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.orderDetails,
        });
    }
  };

  const showCurrentCheckoutStep = () => {
    switch (state.checkoutStep) {
      case CheckoutSteps.confirmOrder:
        return <ConfirmOrder state={state} />;
      case CheckoutSteps.orderDetails:
        return (
          <OrderDetails
            state={state}
            dispatch={dispatch}
            notifyChangeState={updateShippingCosts}
          />
        );
      case CheckoutSteps.paymentSummary:
        return <PaymentSummary dispatch={dispatch} state={state} />;
      default:
        return <ConfirmOrder state={state} />;
    }
  };

  const showPriceBreakdown = () => {
    if (shippingCost) {
      return (
        <div
          className={
            isMobile
              ? 'text__body__regular__small__textNeutral30'
              : 'text__body__regular__large__textNeutral30'
          }
        >
          ${getTotalPrice(state).toFixed(2)} + ${shippingCost.toFixed(2)}{' '}
          shipping
        </div>
      );
    }
    return null;
  };
  const handleMakePayment = async () => {
    try {
      dispatch({ type: 'IS_LOADING', isLoading: true });
      const makePaymentResponse = await StoreController.postOrder(state);
      if (makePaymentResponse) {
        dispatch({ type: 'SET_ORDER_NUMBER', orderId: makePaymentResponse.id });
        dispatch({
          type: 'CHANGE_CHECKOUT_STEP',
          step: CheckoutSteps.paymentSummary,
        });
      }
      dispatch({ type: 'IS_LOADING', isLoading: false });
    } catch (err: any) {
      dispatch({ type: 'IS_LOADING', isLoading: false });
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Order error',
          message: "We couldn't process your order, please try again later.",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const getConfirmButtonText = () => {
    if (state.checkoutStep === CheckoutSteps.confirmOrder) {
      return 'Next';
    }
    if (state.isLoading) {
      return <Spinner color="white" className="spinner-inside-button" />;
    }
    return 'Make payment';
  };

  const buttonAndTotalPrice = () => (
    <>
      <div className={styles.ContainerBreackdown}>
        <div
          className={
            isMobile
              ? 'text__body__regular__small__textNeutral30'
              : 'text__body__regular__large__textNeutral30'
          }
        >
          Items: {getMountOfItems(state)}
        </div>
        <div className={styles.costs}>
          <div
            className={
              isMobile
                ? 'text__body__regular__small__textNeutral40'
                : 'text__body__regular__large__textNeutral40'
            }
          >
            ${priceWithShippingCosts.toFixed(2)}
          </div>
          {showPriceBreakdown()}
        </div>
      </div>
      <Button
        className={classnames(
          styles.ButtonBox,
          state.isLoading ? styles.loading : '',
        )}
        onClick={
          CheckoutSteps.orderDetails === state.checkoutStep
            ? handleMakePayment
            : handleNextButton
        }
        buttonSize={isMobile ? ButtonSize.Small : ButtonSize.Medium}
        buttonType={
          CheckoutSteps.orderDetails === state.checkoutStep
            ? ButtonType.Submit
            : ButtonType.Button
        }
        disabled={
          state.checkoutStep === CheckoutSteps.orderDetails &&
          !checkAllFieldsAreFilled(state)
        }
      >
        {getConfirmButtonText()}
      </Button>
    </>
  );

  const showFooter = () => {
    if (state.checkoutStep !== CheckoutSteps.paymentSummary) {
      if (isMobile && state.checkoutStep === CheckoutSteps.orderDetails) {
        return (
          <div className={styles.footerContainer}>
            <div className={styles.footer}>
              <Accordion
                title={buttonAndTotalPrice()}
                className={styles.accordion}
                classNameIcon={styles.accordionIcon}
                classNametitle={styles.accordionTitle}
                content={<OrderList state={state} />}
              />
            </div>
          </div>
        );
      }
      return (
        <div className={styles.footerContainer}>
          <div className={styles.footer}>{buttonAndTotalPrice()}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={classnames(
        globalStyles.loggedMainContainer,
        styles.fullContent,
      )}
    >
      <div className={styles.checkoutContent}>
        <div className={styles.header}>
          {state.checkoutStep !== CheckoutSteps.paymentSummary ? (
            <BackButton
              onClickFn={handleBackButton}
              className={styles.backButton}
            />
          ) : null}
          <div
            className={classnames(
              'text__heading4__textNeutral40',
              styles.title,
            )}
          >
            {state.checkoutStep === CheckoutSteps.paymentSummary
              ? 'Thank you for your order!'
              : 'Check out'}
          </div>
        </div>
        {showCurrentCheckoutStep()}
        {showFooter()}
      </div>
    </div>
  );
};
