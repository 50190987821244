import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { StoreSerializer } from 'networking/serializers/store-serializer';
import { StoreList } from 'models/store-list';
import { StoreStateType } from 'context/store-context/store-reducer';

class StoreController {
  static async getStoreItems() {
    const response = await ApiService.get<StoreItemRaw[]>(API_ROUTES.STORE);

    const deSerializedItems = StoreSerializer.deSerializeItems(response.data);

    return new StoreList(deSerializedItems);
  }

  static async getShippingCosts() {
    const response = await ApiService.get<ShippingCostRaw[]>(
      API_ROUTES.GET_SHIPPING_COSTS,
    );

    const deSerializedItems = StoreSerializer.deSerializeShippingCosts(
      response.data,
    );

    return deSerializedItems;
  }

  static async postOrder(order: StoreStateType) {
    const response = await ApiService.post<OrderRaw>(
      API_ROUTES.STORE_CREATE_ORDER,
      StoreSerializer.serializeOrder(order),
    );

    return response.data;
  }
}

export { StoreController };
