import { NotificationType } from 'common/enums';
import { Spinner } from 'common/spinner';
import { appActions, AppContext } from 'context/app-context';
import { getQueryParamsObject } from 'helpers/utils';
import { NotificationObject } from 'models/notificationObject';
import { UserController } from 'networking/controllers/user-controller';
import { useContext, useEffect } from 'react';
import { goToPage, RouteName } from 'routes';

const CreateTemporaryUser: React.FC = () => {
  const params = getQueryParamsObject();
  const { dispatch } = useContext(AppContext);
  const createTemporaryUser = async () => {
    try {
      const response = await UserController.createTemporaryUser();
      dispatch({
        type: appActions.SET_TEMPORARY_EMAIL,
        temporaryEmail: params.email,
      });
      if (response && response.user.trialAlbumId) {
        goToPage(RouteName.AlbumDetail, { id: response.user.trialAlbumId });
      }
    } catch (error) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again later.',
          type: NotificationType.Error,
        }),
      });
      goToPage(RouteName.SignIn);
    }
  };

  useEffect(() => {
    createTemporaryUser();
  }, []);
  return <Spinner />;
};

export { CreateTemporaryUser };
