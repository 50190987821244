/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useContext } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { useClickOutside } from 'hooks/click-outside';
import { AppContext, appActions } from 'context';
import { Menu, PositionType } from 'common/menu';
import {
  classnames,
  getClipNameOrAlbumTitle,
  hasAlbumAccess,
  isAnyLoggedUser,
  isFreeTrial,
  isReadOnly,
  isReadOnlySubOrRestrictedAlbum,
  isRestrictedAlbum,
  isTemporaryUser,
} from 'helpers/utils';
import { DeleteIcon, EditIcon, InfoIcon, QrIconFilledIcon } from 'assets/icons';
import { Toggle } from 'common/toggle';
import qrImage from 'assets/images/qrcode.png';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { LinkButton } from 'common/link-button';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { AppLink, RouteName, goToPage } from 'routes';
import { ClipActions } from 'common/enums';
import { BrowseFile } from 'common/browse-file-button/browse-file';
import { SmallPicture } from 'common/small-picture';
import { ModalWarning } from 'common/modal-warning';
import { mbAlbumCoverMaxSize } from 'config/constants';
import { addLinks } from 'helpers/text-helper';
import { createUrlToDownloadFile } from 'helpers/file-utils';
import { ShareClip } from './components';
import styles from './clip-data.module.scss';

type ClipDataProps = {
  clipSelected: ClipType;
  album: AlbumType;
  notifyClipAction: (action: ClipActions, clip: ClipType) => void;
  notifyShowQrCodesExplanation: () => void;
  handleImageUpload: (file: FileResponseType) => void;
  handleRemovePicture: () => void;
};

const ClipData: React.FC<ClipDataProps> = ({
  clipSelected,
  album,
  notifyClipAction,
  notifyShowQrCodesExplanation,
  handleImageUpload,
  handleRemovePicture,
}) => {
  const { state, dispatch } = useContext(AppContext);
  const [showDeletePictureModal, setShowDeletePictureModal] = useState(false);
  const [showClipShareOptions, setShowClipShareOptions] = useState(false);
  const [showModalToSignUp, setShowModalToSignUp] = useState(false);
  const containerClipShareOptionsRef = useRef(null);

  useClickOutside(containerClipShareOptionsRef, () =>
    setShowClipShareOptions(false),
  );

  const { user } = state.data;

  const privateToggleDisabled = !isEmpty(clipSelected.qrCodes);

  const showDownloadButton = () => (
    <Menu position={PositionType.TopLeft} width={styles.clipsOptions}>
      <button
        type="button"
        className={classnames(
          styles.downloadButton,
          'text__body__regular__medium__textNeutral30',
        )}
        onClick={
          isTemporaryUser(user) ? () => setShowModalToSignUp(true) : undefined
        }
      >
        {isTemporaryUser(user) ? (
          <div className="text__body__regular__medium__textNeutral30">
            Download clip
          </div>
        ) : (
          <a
            className="text__body__regular__medium__textNeutral30"
            href={createUrlToDownloadFile(
              clipSelected.videoUrl!,
              clipSelected.name,
            )}
            download={clipSelected.name}
          >
            Download clip
          </a>
        )}
      </button>
      {clipSelected.customThumbnail && (
        <>
          <div className={styles.horizontalLine} />

          <button
            type="button"
            className={classnames(
              styles.downloadButton,
              'text__body__regular__medium__textNeutral30',
            )}
            onClick={
              isTemporaryUser(user)
                ? () => setShowModalToSignUp(true)
                : undefined
            }
          >
            {isTemporaryUser(user) ? (
              <div className="text__body__regular__medium__textNeutral30">
                Download picture
              </div>
            ) : (
              <a
                className="text__body__regular__medium__textNeutral30"
                href={createUrlToDownloadFile(
                  clipSelected.customThumbnail,
                  clipSelected.name,
                )}
                download={clipSelected.name}
              >
                Download picture
              </a>
            )}
          </button>
        </>
      )}
    </Menu>
  );

  const clipShareOptions = () => (
    <div className={styles.shareDots}>
      <div
        role="button"
        tabIndex={0}
        ref={containerClipShareOptionsRef}
        className={classnames(globalStyles.threeDots, styles.dotsColor)}
        onClick={() => setShowClipShareOptions(!showClipShareOptions)}
      >
        {showClipShareOptions && showDownloadButton()}
      </div>
    </div>
  );

  const showMakePrivateToggle = () => (
    <div className={styles.toggle}>
      <Toggle
        disabled={privateToggleDisabled}
        checked={clipSelected.isPrivate}
        onChangeFn={() =>
          notifyClipAction(ClipActions.togglePrivate, clipSelected)
        }
        tooltipText="Videos with associated QR codes cannot be made private"
      />
      <span
        className={classnames(
          'text__body__regular__medium__textNeutral40',
          privateToggleDisabled ? styles.disabledText : '',
        )}
      >
        {' '}
        Make clip private
      </span>
    </div>
  );
  const showMakeAudioOnlyToggle = () => (
    <div className={styles.toggle}>
      <Toggle
        checked={clipSelected.isAudioOnly}
        id="audioOnly"
        onChangeFn={() =>
          notifyClipAction(ClipActions.toggleAudioOnly, clipSelected)
        }
      />
      <span
        className={classnames(
          'text__body__regular__medium__textNeutral40',
          privateToggleDisabled ? styles.disabledText : '',
        )}
      >
        {' '}
        Make clip audio only
      </span>
    </div>
  );

  const showButtonsSection = () => (
    <div className={styles.privateAndAudioOnly}>
      <span className="text__body__semi__bold__medium__textNeutral40">
        Privacy
      </span>
      <div className={styles.privateAndLinks}>
        <div className={styles.leftPrivate}>
          {hasAlbumAccess(user, album) &&
            !isReadOnly(album) &&
            showMakePrivateToggle()}
          <div className={styles.privateDescription}>
            <InfoIcon className={styles.infoIcon} />
            <span
              className={classnames(
                styles.privateDescriptionText,
                'text__body__regular__small__textNeutral30',
              )}
            >
              Private videos can only be seen by you and your contributor(s).
            </span>
          </div>
        </div>
        <div className={styles.rightLink}>
          {!clipSelected.isPrivate && (
            <ShareClip
              clip={clipSelected}
              album={album}
              showModalWarning={isTemporaryUser(user)}
            />
          )}
          {clipShareOptions()}
        </div>
      </div>
      <div className={styles.privateAndLinks}>
        <div className={styles.leftPrivate}>
          {!isReadOnly(album) && showMakeAudioOnlyToggle()}
        </div>
      </div>
      <div className={styles.horizontalLine} />
    </div>
  );

  const modalConfirmationDeletePicture = () => (
    <ModalWarning
      title="Delete picture"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          Are you sure you want to delete this picture?
        </div>
      }
      successButtonText="Delete picture"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowDeletePictureModal(false)}
      successFn={() => {
        setShowDeletePictureModal(false);
        handleRemovePicture();
      }}
    />
  );

  const showDeletePictureButton = () => (
    <div className={styles.editClipContainer}>
      <Button
        type="button"
        buttonStyle={ButtonStyle.GreyGhost}
        buttonSize={ButtonSize.Small}
        className={styles.editClipContainer}
        onClick={() => setShowDeletePictureModal(true)}
      >
        <DeleteIcon className={styles.editIcon} />
        <span className="text__body__regular__small__textNeutral30">
          Delete picture
        </span>
      </Button>
    </div>
  );

  const showEditClipButton = () => (
    <div className={styles.editClipContainer}>
      <Button
        type="button"
        onClick={() => {
          notifyClipAction(ClipActions.editClip, clipSelected);
        }}
        buttonStyle={ButtonStyle.GreyGhost}
        buttonSize={ButtonSize.Small}
        className={styles.editClipContainer}
      >
        <EditIcon className={styles.editIcon} />
        <span className="text__body__regular__small__textNeutral30">
          Edit name and description
        </span>
      </Button>
    </div>
  );

  const showClipInfo = () => (
    <div className={styles.clipInfo}>
      <div className={styles.clipInfoButtons}>
        <div className={styles.nameAndDescription}>
          <div className={styles.nameRow}>
            <div className="text__heading5__textNeutral40">
              {clipSelected.name}
            </div>
          </div>
          <div
            className={classnames(
              'text__body__regular__medium__textNeutral30',
              styles.descriptionRow,
            )}
          >
            {addLinks(clipSelected.description)}
          </div>
        </div>
        <div className={styles.buttonActions}>
          {/* eslint-disable-next-line max-len */}
          {hasAlbumAccess(user, album) &&
            !isRestrictedAlbum(album) &&
            !isReadOnly(album) &&
            showEditClipButton()}

          {!isRestrictedAlbum(album) &&
            !isReadOnly(album) &&
            clipSelected.customThumbnail &&
            hasAlbumAccess(user, album) &&
            showDeletePictureButton()}
        </div>
      </div>
      {clipSelected.customThumbnail && !clipSelected.isAudioOnly && (
        <SmallPicture picture={clipSelected.customThumbnail} />
      )}
    </div>
  );

  const showUploadPictureOption = () => (
    <>
      <div className={styles.horizontalLine} />
      <div className={styles.uploadPictureSection}>
        <div className={styles.uploadPictureTitle}>
          <div className="text__body__semi__bold__medium__textNeutral40">
            Picture
          </div>
          <div className="text__body__regular__medium__textNeutral20">
            {' '}
            (Optional)
          </div>
        </div>
        <div className={styles.uploadPictureButton}>
          <BrowseFile
            maxFileSizeInMb={mbAlbumCoverMaxSize}
            onSuccessfulUpload={handleImageUpload}
            content="Browse files"
            showInfoIcon
          />
        </div>
      </div>
    </>
  );
  const showQrCodesList = () => (
    <>
      <div className="text__body__regular__medium__textNeutral40">
        This clip has been linked to the following QR Codes:
      </div>
      <div className={styles.qrCodes}>
        {clipSelected.qrCodes.map((code) => (
          <div key={code.id} className={styles.qrCodeItem}>
            <div>
              <button
                onClick={() =>
                  dispatch({
                    type: appActions.SET_IMAGE_TO_ENLARGE,
                    imageToEnlarge: code.image || qrImage,
                    isQrCodeImageEnlarged: true,
                  })
                }
                type="button"
              >
                <img
                  className={styles.qrImage}
                  src={code.image || qrImage}
                  alt="QR code"
                />
              </button>
            </div>
            <div>
              <div className="text__body__semi__bold__medium__textNeutral40">
                {getClipNameOrAlbumTitle(code.clipId, album)}
              </div>
              <div className="text__body__regular__small__textNeutral30">
                By {code.owner}
              </div>
              <div className="text__body__regular__small__textNeutral30">
                {dayjs(code.linkDate).format('MMMM Do, YYYY')}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.infoContainer}>
        <InfoIcon className={styles.infoIcon} />
        <span className="text__body__regular__tiny__textNeutral30">
          Buy QR codes from the{' '}
          <AppLink className={styles.infoLink} routeName={RouteName.Store}>
            <span className="text__body__regular__tiny__primary50">
              Generational Story Store
            </span>
          </AppLink>
          {'. '}
          Manage QR codes from the Generational Story App.
        </span>
      </div>
    </>
  );

  const showEmptyList = () => (
    <div className={styles.emptyList}>
      <QrIconFilledIcon className={styles.icon} />
      <div className={styles.infoSection}>
        <div className="text__body__regular__overline__textNeutral30">
          KEEP YOUR MEMORIES ACCESSIBLE ACROSS THE GENERATIONS.
        </div>
        <div className={styles.bottomSection}>
          <div className={styles.buttons}>
            <div className="text__body__regular__small__textNeutral40">
              Link your memories with the physical world with QR code stickers
              and plaques.
            </div>
            <Button
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              buttonSize={ButtonSize.Small}
              onClick={notifyShowQrCodesExplanation}
              className={styles.learnMoreButton}
            >
              Learn more.
            </Button>
          </div>
          <div className={styles.buttons}>
            <LinkButton
              onClick={() => goToPage(RouteName.Store)}
              tooltip={
                isFreeTrial(album) || isReadOnlySubOrRestrictedAlbum(album)
              }
              tooltipContent="Available under standard, lifetime or legacy plans"
              tooltipPosition="top"
              tooltipId="tooltip-get-qr-codes"
              disabled={
                isFreeTrial(album) || isReadOnlySubOrRestrictedAlbum(album)
              }
            >
              <span className={styles.inOneLine}>Get QR codes</span>
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );

  const showModalSignUp = () => (
    <ModalWarning
      title="Sign up to share or download your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            Please complete the sign up process to share or download your clips,
            as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalToSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  const showLinkedQrCodes = () => (
    <div className={styles.qrCodesList}>
      <div className={styles.qrTitle}>
        <div className="text__body__semi__bold__medium__textNeutral40">
          QR Code(s)
        </div>
        <div className="text__body__regular__medium__textNeutral20">
          {' '}
          (Optional)
        </div>
      </div>
      {isEmpty(clipSelected.qrCodes) ? showEmptyList() : showQrCodesList()}
    </div>
  );

  return (
    <>
      {showDeletePictureModal && modalConfirmationDeletePicture()}
      {showModalToSignUp && showModalSignUp()}
      <div className={styles.clipData}>
        {showClipInfo()}

        {isAnyLoggedUser(user) &&
          !isReadOnlySubOrRestrictedAlbum(album) &&
          !clipSelected.isAudioOnly &&
          !clipSelected.customThumbnail &&
          showUploadPictureOption()}

        <div className={styles.horizontalLine} />
        {isAnyLoggedUser(user) &&
          !isRestrictedAlbum(album) &&
          showButtonsSection()}

        {showLinkedQrCodes()}

        <div className={styles.horizontalLine} />
        <div className={styles.extraLine} />
      </div>
    </>
  );
};

export { ClipData };
