import React, { useState, useEffect, useContext } from 'react';
import { Spinner } from 'common/spinner';
import { Button, ButtonSize, ButtonType } from 'common/button';
import { RouteName, goToPage } from 'routes';

import { classnames } from 'helpers/utils';
import { UserController } from 'networking/controllers/user-controller';
import { AppContext, appActions } from 'context';
import CypressIds from 'cypressIds';
import styles from './sign-up-footer.module.scss';

type SignUpFooterProps = {
  token?: string;
  enableNext?: boolean;
  registerFn?: () => Promise<boolean>;
  isCompany?: boolean;
};

const SignUpFooter: React.FC<SignUpFooterProps> = ({
  token,
  enableNext,
  registerFn,

  isCompany,
}) => {
  const { dispatch } = useContext(AppContext);

  const [fetching, setFetching] = useState(false);
  const registerButtonText = isCompany ? 'Activate' : 'Create account';

  useEffect(() => setFetching(false), []);

  const disabledNextButton = () => !enableNext || fetching;

  const registerUser = async () => {
    setFetching(true);

    const userRegistered = await registerFn!();

    if (!userRegistered) {
      setFetching(false);
      return;
    }

    // contributor token
    if (token) {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });
      goToPage(RouteName.Albums);
    } else {
      goToPage(RouteName.EmailWaitingConfirm);
    }
  };

  const handleRegisterUser = async () => {
    setFetching(true);

    registerUser();

    setFetching(false);
  };

  const showRegisterButton = () => (
    <Button
      className={classnames(
        styles.registerButton,
        fetching ? 'noClick staticSizeRegister' : '',
      )}
      onClick={() => handleRegisterUser()}
      buttonSize={ButtonSize.Medium}
      buttonType={ButtonType.Submit}
      dataCy={CypressIds.signupButton}
      disabled={disabledNextButton()}
    >
      {fetching ? (
        <Spinner color="white" className="spinner-inside-button" />
      ) : (
        registerButtonText
      )}
    </Button>
  );

  return (
    <div className={classnames(styles.buttons)}>{showRegisterButton()}</div>
  );
};

export { SignUpFooter };
