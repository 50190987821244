import { LabeledText } from 'common/labeled-text';
import { LinkButton } from 'common/link-button';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { classnames } from 'helpers/utils';
import { goToPage, RouteName } from 'routes';
import styles from './owner-section.module.scss';
import aboutPageStyles from '../../about-album.module.scss';

type OwnerSectionProps = {
  album: AlbumType;
  isOwner: boolean;
};

const OwnerSection = ({ album, isOwner }: OwnerSectionProps) => (
  <div className={classnames(globalStyles.infoCard, styles.ownerSection)}>
    <p className="text__title2__textNeutral40">Owner</p>
    <LabeledText
      label="Album Owner"
      text={`${album.owner.name} (${album.owner.email})`}
    />
    {album.owner.companyName && (
      <LabeledText label="Company name" text={album.owner.companyName} />
    )}
    {isOwner && (
      <div className={aboutPageStyles.buttonSection}>
        <LinkButton onClick={() => goToPage(RouteName.Account)}>
          Modify in account settings
        </LinkButton>
      </div>
    )}
  </div>
);

export { OwnerSection };
