import React, { useContext } from 'react';
import pluralize from 'pluralize';
import { AppContext } from 'context/app-context';
import { dayjs } from 'helpers/dayjs';
import { KeyIcon, QrTinyIcon } from 'assets/icons';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import {
  calculateHoursAndMinutes,
  classnames,
  isLockedAlbum,
  isOwner,
  isRestrictedAlbum,
  showTextEllipsis,
} from 'helpers/utils';
import { LinkButton } from 'common/link-button';
import { isEmpty } from 'lodash';
import { Button, ButtonStyle } from 'common/button';
import CypressIds from 'cypressIds';
import styles from './album-card-large.module.scss';

type AlbumCardLargeProps = {
  album: AlbumType;
  showAlbumStatusLabel: Function;
  notifyClickCard: (id: number, keyEvent?: boolean) => () => void;
  notifyClickQrIcon: (id: number) => void;
};

const AlbumCardLarge: React.FC<AlbumCardLargeProps> = ({
  album,
  showAlbumStatusLabel,
  notifyClickCard,
  notifyClickQrIcon,
}) => {
  const { state } = useContext(AppContext);
  const { user } = state.data;

  const { hours, minutes } = calculateHoursAndMinutes(album.totalTime);

  const showDefaultPicture = () => (
    <div className={styles.defaultpicture}>
      <Logo className={styles.logo} />
      <p className="text__heading4__textNeutral30">
        {showTextEllipsis(album.title, 20)}
      </p>
      <p className="text__heading6__textNeutral30">A life story</p>
    </div>
  );

  const showOwnerIcon = () => <KeyIcon className={styles.keyIcon} />;

  const showCard = () => (
    <button
      type="button"
      className={styles.cardLarge}
      disabled={isLockedAlbum(album)}
      onClick={notifyClickCard(album.id)}
      onKeyDown={notifyClickCard(album.id, true)}
      data-cy={CypressIds.albumCard}
    >
      <div
        className={classnames(styles.pictureBox, {
          [styles.greyImage]: isRestrictedAlbum(album) || isLockedAlbum(album),
        })}
      >
        {showAlbumStatusLabel(album, styles.statusLabelPosition)}

        {album.coverUrl ? (
          <img src={album.coverUrl} className={styles.picture} alt="logo" />
        ) : (
          showDefaultPicture()
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.titleAndOwner}>
          <div className="text__body__semi__bold__medium__textNeutral40 mb-6">
            {showTextEllipsis(album.title, 45)}
          </div>
          <div>
            {!isEmpty(album.qrCodes) && (
              <Button
                className={styles.qrIconButton}
                buttonStyle={ButtonStyle.PrimaryGhost}
                onClick={(event) => {
                  event.stopPropagation();
                  notifyClickQrIcon(album.id);
                }}
              >
                <QrTinyIcon className={styles.qrIcon} />
              </Button>
            )}
            {isOwner(user, album) && showOwnerIcon()}
          </div>
        </div>
        <div className="text__body__regular__medium__textNeutral30">{`Last updated: ${dayjs(
          album.lastUpdate,
        ).fromNow()}`}</div>
        {album.owner.companyName && !isOwner(user, album) && (
          <div className="text__body__regular__medium__textNeutral20">{`Powered By: ${showTextEllipsis(
            album.owner.companyName,
            30,
          )}`}</div>
        )}

        <div className={styles.AllFooter}>
          <div className={styles.horizontalLine} />
          <div className={styles.bottomSection}>
            <div className={styles.cardClipsAndTime}>
              <div className={styles.clips}>
                <p className="text__body__semi__bold__medium__textNeutral40">
                  {album.clipAmount}
                </p>
                <p className="text__body__regular__small__textNeutral30">
                  {' '}
                  Clips{' '}
                </p>
              </div>

              <div className={styles.verticalLine} />

              <div className={styles.hoursAndMinutes}>
                <div className={styles.hours}>
                  <p className="text__body__semi__bold__medium__textNeutral40">
                    {hours}
                  </p>
                  <p className="text__body__regular__small__textNeutral30">
                    {pluralize('hour', hours)}
                  </p>
                </div>
                <div className={styles.minutes}>
                  <p className="text__body__semi__bold__medium__textNeutral40">
                    {minutes}
                  </p>
                  <p className="text__body__regular__small__textNeutral30">
                    {pluralize('minute', minutes)}
                  </p>
                </div>
              </div>
            </div>
            <LinkButton className={styles.viewAlbumLink}>View album</LinkButton>
          </div>
        </div>
      </div>
    </button>
  );

  return showCard();
};

export { AlbumCardLarge };
