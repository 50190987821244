const appActions = {
  USER_LOGGED: 'USER_LOGGED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  ACTUAL_SECTION: 'ACTUAL_SECTION',
  NOTIFICATION: 'NOTIFICATION',
  BETA_MSG: 'BETA_MSG',
  SET_META_TAGS: 'SET_META_TAGS',
  SUBSCRIPTIONS_SCHEME: 'SUBSCRIPTIONS_SCHEME',
  SET_IMAGE_TO_ENLARGE: 'SET_IMAGE_TO_ENLARGE',
  SET_TEMPORARY_EMAIL: 'SET_TEMPORARY_EMAIL',
};

export { appActions };
