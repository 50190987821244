import React from 'react';
import { Accordion } from 'common/accordion';
import { AlbumTabs, Breakpoints } from 'common/enums';
import { classnames } from 'helpers/utils';
import { useMediaQuery } from 'hooks/use-media-query';
import { Button, ButtonSize, ButtonStyle } from 'common/button';

import styles from './answered-questions.module.scss';

type AnsweredQuestionsProps = {
  questions?: ClipType[];
  handleTabSelected: (queryParams: ParamsType) => void;
};

const AnsweredQuestions: React.FC<AnsweredQuestionsProps> = ({
  questions = [],
  handleTabSelected,
}) => {
  const isMobileOrTablet = useMediaQuery(`(max-width: ${Breakpoints.md}px)`);

  const showList = () => (
    <div className={styles.row}>
      {questions.map((question: ClipType) => (
        <div
          key={question.id}
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            styles.questionElement,
          )}
        >
          {question.name}
        </div>
      ))}
    </div>
  );

  const emptyList = () => (
    <div className={styles.empty}>
      <p className="text__title2__textNeutral30"> Nothing to see here yet.</p>
      <p className="text__body__regular__medium__textNeutral30">
        Go to the{' '}
        <Button
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          buttonSize={ButtonSize.Medium}
          onClick={() => handleTabSelected({ tab: AlbumTabs.recordUpload })}
        >
          record / upload
        </Button>{' '}
        tab to begin recording your story, or download the Generational Story
        app.
      </p>
    </div>
  );

  const showQuestionsList = () => (questions.length ? showList() : emptyList());

  return (
    <div className={styles.answeredContainer}>
      {isMobileOrTablet ? (
        <Accordion
          title="Answered questions"
          className="text__title2__textNeutral40"
          content={questions.length ? showList() : emptyList()}
        />
      ) : (
        showQuestionsList()
      )}
    </div>
  );
};

export { AnsweredQuestions };
