/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { goToPage, RouteName } from 'routes';
import { InterviewScript } from 'common/interview-script';
import { AnsweredQuestions } from 'common/answered-questions';
import { AlbumsController } from 'networking/controllers/albums-controller';
import {
  classnames,
  isTemporaryUser,
  questionsHaveChanged,
} from 'helpers/utils';
import { ModalWarning } from 'common/modal-warning';
import { QuestionsManagmentViewType } from 'common/enums';
import { ButtonStyle } from 'common/button';
import { NotificationObject } from 'models/notificationObject';
import { ChevronRightIcon, InfoIcon } from 'assets/icons';
import { appActions, AppContext } from 'context';
import { cloneDeep, debounce } from 'lodash';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { InfoBadge } from 'common/info-badge';
import styles from './plan-interview.module.scss';

type PlanInterviewProps = {
  album: AlbumType;
  maxQuestionsAllowed: number;
  unansweredQuestions?: QuestionType[];
  notifySucess: (unasQuestions: QuestionType[]) => void;
  handleTabSelected: (queryParams: ParamsType) => void;
};

const PlanInterview: React.FC<PlanInterviewProps> = ({
  album,
  maxQuestionsAllowed,
  unansweredQuestions = [],
  notifySucess,
  handleTabSelected,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const { id: albumId, clips } = album;

  const [originalUnansweredQuestions, setOriginalUnansweredQuestions] =
    useState<QuestionType[]>(cloneDeep(unansweredQuestions));

  const [updatedData, setUpdatedData] = useState<{
    questions: QuestionType[];
    templateIds: string[];
  }>({ questions: unansweredQuestions, templateIds: [] });

  const [showCancelChangesModal, setShowCancelChangesModal] = useState(false);

  const setApiError = useHandleApiError(() => {
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({ show: true }),
    });
  });

  const getAnsweredPropertiesForRequest = (
    qs: ClipType[],
  ): QuestionEditRequestType[] =>
    qs.map((question) => ({
      id: question.id,
      name: question.name,
      description: question?.description,
      isPrivate: question.isPrivate,
    }));

  const getUnansweredPropertiesForRequest = (
    qs: QuestionType[],
  ): QuestionEditRequestType[] =>
    qs.map((question) => ({
      id: question.isNew ? undefined : question.id,
      name: question.name,
    }));

  const concatAllQuestions = (
    answeredQs: ClipType[],
    unansweredQs: QuestionType[],
  ) => {
    const answ = getAnsweredPropertiesForRequest(answeredQs);
    const unansw = getUnansweredPropertiesForRequest(unansweredQs);

    return unansw.concat(answ);
  };

  const handleCancelRedirectByUserType = () => {
    goToPage(RouteName.Albums);
  };

  const handleSaveAlbumQuestionsAndRedirect = (unansweredQ: QuestionType[]) => {
    const requestData = {
      album: {
        questions: concatAllQuestions(clips || [], unansweredQ),
        templateIds: updatedData.templateIds,
      },
    };

    return AlbumsController.editAlbumQuestions(albumId, requestData);
  };

  // Save questions at most once per second
  const saveAlbumQuestionsAndTemplatesIds = useCallback(
    debounce(
      async (updatedAllData) => {
        try {
          const requestData = {
            album: {
              questions: concatAllQuestions(
                clips || [],
                updatedAllData.questions,
              ),
              templateIds: updatedAllData.templateIds,
            },
          };
          await AlbumsController.editAlbumQuestions(albumId, requestData);
          notifySucess(updatedAllData.questions);
        } catch (e: any) {
          setApiError(e);
        }
      },
      1000,
      { trailing: true },
    ),
    [clips, albumId, setOriginalUnansweredQuestions],
  );

  useEffect(() => {
    if (questionsHaveChanged(updatedData.questions, unansweredQuestions)) {
      setOriginalUnansweredQuestions(unansweredQuestions);
      setUpdatedData({ ...updatedData, questions: unansweredQuestions });
    }
  }, [unansweredQuestions]);

  useEffect(() => {
    // Save questions any time the number, order, or names of questions changes
    if (
      questionsHaveChanged(originalUnansweredQuestions, updatedData.questions)
    ) {
      saveAlbumQuestionsAndTemplatesIds(updatedData);
      setOriginalUnansweredQuestions(updatedData.questions);
    }
  }, [updatedData]);

  const showWarningModalComponent = () => (
    <ModalWarning
      title="Cancel Changes"
      content="Are you sure you want to discard unsaved changes?"
      successButtonText="Discard"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowCancelChangesModal(false)}
      successFn={() => {
        handleCancelRedirectByUserType();
      }}
    />
  );
  const showQuestionsMaxCountReachedLabel = () => (
    <InfoBadge
      icon={<InfoIcon className={classnames(styles.infoIcon)} />}
      className={styles.banner}
      message={`${album?.clips?.length} out of ${maxQuestionsAllowed} clips recorded.`}
      showAction={isTemporaryUser(state.data.user)}
      buttonAction={() => goToPage(RouteName.SignUp)}
      buttonText="Sign up to upgrade"
      buttonIcon={<ChevronRightIcon />}
    />
  );

  return (
    <>
      {showCancelChangesModal && showWarningModalComponent()}
      <div className={styles.formContainer}>
        <div className={styles.answeredColumn}>
          <div
            className={classnames(
              'text__title2__textNeutral30',
              styles.answeredTitle,
            )}
          >
            Answered questions
          </div>

          {showQuestionsMaxCountReachedLabel()}

          <div className={styles.dividerLine} />

          <div className={styles.answeredQuestions}>
            <AnsweredQuestions
              questions={clips}
              handleTabSelected={handleTabSelected}
            />
          </div>
        </div>
        <div className={styles.unansweredColumn}>
          <InterviewScript
            album={album}
            maxQuestionsAllowed={maxQuestionsAllowed}
            title="Unanswered questions"
            questionsManagmentView={QuestionsManagmentViewType.editAlbum}
            unansQuestions={updatedData.questions}
            clips={clips}
            setInterviewScript={(
              questions: QuestionType[],
              templateIds: string[],
            ) => setUpdatedData({ questions, templateIds })}
            notifySaveNewQuestionsAndRedirect={(unasnweredQ: QuestionType[]) =>
              handleSaveAlbumQuestionsAndRedirect(unasnweredQ)
            }
          />
        </div>
      </div>
    </>
  );
};

export { PlanInterview };
