import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { ChevronRightIcon, InfoIcon, LinkIcon, ShareIcon } from 'assets/icons';
import { useContext, useState } from 'react';
import { SelfRecordingContext } from 'context/self-recording-context';
import { AppContext } from 'context';
import {
  classnames,
  isAnyLoggedUser,
  isFreeTrial,
  isOwner,
  showTextEllipsis,
  totalAlbumVideoClips,
  userPermissionUpgrade,
} from 'helpers/utils';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import { Select } from 'common/select';
import { LocalStorageApi } from 'helpers/local-storage';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { InfoBadge } from 'common/info-badge/info-badge';
import { CameraRecord } from './components/camera-record';
import { UploadVideos } from './components/upload-videos';
import styles from './camera-upload.module.scss';

type CameraUploadProps = {
  album: AlbumType;
  selectedQuestion?: ClipType;
  notifySelectQuestion: (id?: string) => void;
  notifySelectCustomRecording: () => void;
  notifyShowInviteModal?: () => void;
  upgradeAction?: () => void;
  refetchAlbum: () => Promise<void>;
};

const CameraUpload: React.FC<CameraUploadProps> = ({
  album,
  selectedQuestion,
  notifySelectQuestion,
  notifySelectCustomRecording,
  notifyShowInviteModal,
  upgradeAction,
  refetchAlbum,
}) => {
  const { state, dispatch } = useContext(SelfRecordingContext);
  const { state: generalState } = useContext(AppContext);
  const [chooseMode, setChooseMode] = useState<boolean>(state.chooseMode);
  const [uploadMode, setUploadMode] = useState(state.uploadMode);
  const toggleUploadMode = () => {
    setUploadMode(!uploadMode);
    dispatch({
      type: selfRecordingActions.setUploadMode,
      uploadMode: !uploadMode,
    });
  };
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const maxQuestionsAllowed = album.maxClips;
  const guestToken = LocalStorageApi.get('guestToken');

  const buttonText = uploadMode ? 'Record a video' : 'Upload a video';

  const showTitle = () => {
    if (chooseMode) {
      return 'What do you want to do first?';
    }
    if (uploadMode) {
      return 'Upload Video';
    }
    return 'Record Video';
  };

  const showSelectMode = () => (
    <div className={styles.buttonContainer}>
      <Button
        buttonSize={ButtonSize.Medium}
        buttonType={ButtonType.Button}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => {
          setChooseMode(false);
          setUploadMode(false);
          dispatch({
            type: selfRecordingActions.chooseMode,
            uploadMode: false,
          });
          dispatch({
            type: selfRecordingActions.setUploadMode,
            uploadMode: false,
          });
        }}
      >
        Record videos
      </Button>
      <Button
        buttonSize={ButtonSize.Medium}
        buttonType={ButtonType.Button}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => {
          setChooseMode(false);
          setUploadMode(true);
          dispatch({
            type: selfRecordingActions.chooseMode,
          });
          dispatch({
            type: selfRecordingActions.setUploadMode,
            uploadMode: true,
          });
        }}
      >
        Upload videos
      </Button>
    </div>
  );

  const showCamera = () => (
    <CameraRecord
      album={album}
      upgradeAction={upgradeAction}
      refetchAlbum={refetchAlbum}
    />
  );

  const showUpload = () => (
    <UploadVideos
      album={album}
      clipsExistingAmount={totalAlbumVideoClips(album)}
      refetchAlbum={refetchAlbum}
    />
  );

  const onSelectQuestion = (option: SelectType) => {
    if (option.id !== '-1') {
      notifySelectQuestion(option.id as string);
    } else {
      notifySelectCustomRecording();
    }
  };

  const showMode = () => {
    if (chooseMode) {
      return showSelectMode();
    }
    if (uploadMode) {
      return showUpload();
    }
    return showCamera();
  };

  return (
    <div className={styles.allPage}>
      <div className={styles.titleAndButton}>
        <div className={chooseMode ? styles.selectModeQuestion : styles.title}>
          {showTitle()}
        </div>
        {!chooseMode && (
          <div className={styles.buttonAndText}>
            <span className="text__body__regular__overline__textNeutral30">
              OR
            </span>
            <Button
              buttonSize={ButtonSize.Small}
              buttonType={ButtonType.Button}
              buttonStyle={ButtonStyle.PrimaryStroke}
              disabled={
                state.isRecording ||
                state.isUploading ||
                (isAnyLoggedUser(generalState.data.user) &&
                  !generalState.data.user.permissions?.permissions.uploadVideos)
              }
              onClick={toggleUploadMode}
            >
              <div className={styles.buttonAndIcon}>
                <ShareIcon />
                {buttonText}
              </div>
            </Button>
          </div>
        )}
      </div>
      {!chooseMode && (
        <div className={styles.questionSelected}>
          <span className="text__body__regular__overline__textNeutral30">
            QUESTION SELECTED
          </span>
          {isMobile ? (
            <Select
              options={[
                { id: '-1', value: 'Custom recording' },
                ...(album?.unansweredQuestions?.map((question: ClipType) => ({
                  id: question.id,
                  value: question.name,
                })) ?? []),
              ]}
              optionSelected={{
                id: state.selectedQuestion?.id ?? '-1',
                value: state.selectedQuestion?.name ?? 'Custom recording',
              }}
              onClickOption={onSelectQuestion}
              id="questions"
              value={
                selectedQuestion
                  ? showTextEllipsis(selectedQuestion?.name, 60)
                  : 'Custom recording'
              }
              placeholder="Select a question"
            />
          ) : (
            <span className="text__body__semi__bold__medium__textNeutral40">
              {selectedQuestion ? selectedQuestion?.name : 'Custom recording'}
            </span>
          )}
        </div>
      )}
      {showMode()}
      {!chooseMode && (
        <InfoBadge
          icon={<InfoIcon className={classnames(styles.infoIcon)} />}
          className={styles.banner}
          message={`${album?.clips?.length} out of ${maxQuestionsAllowed} clips recorded.`}
          showAction={
            isOwner(generalState.data.user, album) &&
            isFreeTrial(album) &&
            userPermissionUpgrade(generalState.data.user)
          }
          buttonAction={upgradeAction}
          buttonText="Upgrade album"
          buttonIcon={<ChevronRightIcon />}
        />
      )}

      {isMobile && !guestToken && (
        <>
          <div className={styles.lineSeparator} />
          <div className={styles.inviteGuest}>
            <span className="text__body__regular__overline__textNeutral30">
              WANT SOMEONE ELSE TO RECORD?
            </span>
            <Button
              className={styles.copyLinkButton}
              buttonStyle={ButtonStyle.PrimaryStroke}
              buttonSize={ButtonSize.Small}
              onClick={notifyShowInviteModal}
            >
              <LinkIcon />
              Invite guest
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export { CameraUpload };
