import { classnames } from 'helpers/utils';
import {
  DeleteIcon,
  MinusIconCirculeIcon,
  PlusIconCirculeIcon,
} from 'assets/icons';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import styles from './store-components.module.scss';
import { StoreStateType } from '../../../context/store-context/store-reducer';

type CardItemInCartProps = {
  image: string;
  name: string;
  price: number;
  mountOfItems: number;
  id: number;
  state: StoreStateType;
  dispatch: React.Dispatch<any>;
};

const CardItemInCart: React.FC<CardItemInCartProps> = ({
  name,
  price,
  image,
  mountOfItems,
  dispatch,
  id,
}) => {
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const handleDeleteItem = (itemId: number) => {
    dispatch({ type: 'REMOVE_ITEM_CART', id: itemId });
  };
  const handleAddItem = (itemId: number) => {
    dispatch({ type: 'ONE_ITEM_MORE', id: itemId });
  };
  const handleLessItem = (itemId: number) => {
    dispatch({ type: 'ONE_ITEM_LESS', id: itemId });
  };
  return (
    <div className={classnames(styles.card)}>
      <div className={classnames(styles.cardContentInCart)}>
        <div className={styles.cardItemInCartInformation}>
          <div className={classnames(styles.cardImageInCart)}>
            <img
              src={image}
              className={classnames(styles.picture)}
              alt="Item in cart"
            />
          </div>
          <div className={classnames(styles.nameAndPrice)}>
            <div
              className={
                isMobile
                  ? 'text__body__regular__medium__textNeutral40'
                  : 'text__title1__textNeutral40'
              }
            >
              {name}
            </div>
            <div
              className={
                isMobile
                  ? 'text__body__regular__medium__textNeutral40'
                  : 'text__title2__textNeutral30'
              }
            >
              $ {price.toFixed(2)}
            </div>
          </div>
        </div>
        <div className={styles.actionsBoxInCart}>
          <button
            className={styles.actionIcons}
            type="button"
            onClick={() => {
              handleLessItem(id);
            }}
            disabled={mountOfItems === 1}
          >
            <MinusIconCirculeIcon />
          </button>
          <div
            className={
              isMobile
                ? 'text__body__regular__medium__textNeutral40'
                : 'text__body__regular__large__textNeutral40'
            }
          >
            {' '}
            {mountOfItems}
          </div>
          <button
            className={styles.actionIcons}
            type="button"
            onClick={() => {
              handleAddItem(id);
            }}
          >
            <PlusIconCirculeIcon />
          </button>
          <div className={classnames(styles.deleteIconBox)}>
            <button
              className={styles.deleteIcon}
              type="button"
              onClick={() => {
                handleDeleteItem(id);
              }}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardItemInCart };
