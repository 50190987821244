import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { QuestionsSelector } from 'common/questions-selector';
import { CameraUpload } from 'common/camera-upload';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { Modal } from 'common/modal';
import { RecordingGuest } from 'models/recording-guest';
import {
  classnames,
  copyToClipboard,
  isFreeTrial,
  isTemporaryUser,
  showTextEllipsis,
  userPermissionUpgrade,
} from 'helpers/utils';
import { AppContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { Breakpoints, NotificationType } from 'common/enums';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { useMediaQuery } from 'hooks/use-media-query';
import { LinkIcon } from 'assets/icons';
import { AlbumLimitedConditionWarning } from 'common/album-limited-condition-warning';
import { AlbumLimitedConditionWarningType } from 'common/album-limited-condition-warning/album-limited-condition-warning';
import { ModalWarning } from 'common/modal-warning';
import { goToPage, RouteName } from 'routes';
import { InviteGuestModal } from './components/invite-guest-modal';
import styles from './record-upload.module.scss';

type RecordUploadProps = {
  album: AlbumType;
  setShowLearnMoreSubscriptionModal: Dispatch<React.SetStateAction<boolean>>;
  upgradeAction: () => void;
  refetchAlbum: () => Promise<void>;
};

const RecordUpload: React.FC<RecordUploadProps> = ({
  album,
  setShowLearnMoreSubscriptionModal,
  upgradeAction,
  refetchAlbum,
}) => {
  const {
    dispatch: generalDispatch,
    state: {
      data: { user },
    },
  } = useContext(AppContext);
  const { state, dispatch } = useContext(SelfRecordingContext);
  const [isCustomRecording, setIsCustomRecording] = useState<boolean>(false);
  const [invitedGuest, setInvitedGuest] = useState<RecordingGuest | undefined>(
    undefined,
  );
  const [showModalSignUp, setShowModalSignUp] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const maxQuestionAllowed = album.maxClips;
  const onSelectCustomRecording = () => {
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: undefined,
    });
    setIsCustomRecording(true);
  };
  const showWarningLimitCondition =
    isFreeTrial(album) &&
    maxQuestionAllowed === album.clipAmount &&
    state.chooseMode;

  const showWarningAlbumLimitedCondition = () => (
    <AlbumLimitedConditionWarning
      title="Your trial album is full"
      type={AlbumLimitedConditionWarningType.Alert}
      content={
        <div className={styles.warningAlbumLimit}>
          <Button
            buttonStyle={ButtonStyle.SecondaryGhostLink}
            buttonSize={ButtonSize.Medium}
            onClick={() => setShowLearnMoreSubscriptionModal(true)}
          >
            Upgrade to a subscription
          </Button>
          <span className="text__body__regular__medium__textNeutral40">
            {' '}
            to increase your limit.
          </span>
        </div>
      }
    />
  );

  const selectTheNextQuestion = useCallback(() => {
    if (state.chooseMode) return;
    const questionSelectedUna = album.unansweredQuestions?.find(
      (item) => item.id === state.selectedQuestion?.id,
    );
    if (!questionSelectedUna) {
      const question = album.unansweredQuestions
        ? album.unansweredQuestions[0]
        : undefined;
      if (state.uploadMode || !question) {
        onSelectCustomRecording();
      } else {
        setIsCustomRecording(false);
        dispatch({
          type: selfRecordingActions.setSelectedQuestion,
          selectedQuestion: question,
        });
      }
    }
  }, [state.chooseMode, state.uploadMode]);

  useEffect(() => {
    if (isTemporaryUser(user) && album.maxClips === album.clipAmount) {
      setShowModalSignUp(true);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: selfRecordingActions.setAlbumId, albumId: album.id });
    selectTheNextQuestion();
  }, [state.chooseMode, state.uploadMode]);

  const onSelectQuestion = (id?: string) => {
    setIsCustomRecording(false);
    const question = album.unansweredQuestions?.find((item) => item.id === id);
    if (state.videosRecorded.length > 0) {
      dispatch({
        type: selfRecordingActions.deleteAllVideos,
      });
    }
    dispatch({
      type: selfRecordingActions.setSelectedQuestion,
      selectedQuestion: question,
    });
  };

  const [showInviteGuestModal, setShowInviteGuestModal] = useState(false);
  const [showInviteLinkModal, setShowInviteLinkModal] = useState(false);

  const onClickInvite = (guest: RecordingGuest) => {
    setShowInviteGuestModal(false);
    setShowInviteLinkModal(true);
    setInvitedGuest(guest);
  };

  const showInviteGuestModalComponent = () => (
    <InviteGuestModal
      onClose={() => setShowInviteGuestModal(false)}
      albumId={album.id}
      notifyGuestInvited={onClickInvite}
    />
  );

  const onCopyLink = () => {
    copyToClipboard(invitedGuest!.album_url);
    setShowInviteLinkModal(false);
    generalDispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Link copied!',
        noMessage: true,
        type: NotificationType.Success,
      }),
    });
  };

  const showInviteLinkModalComponent = () => (
    <Modal
      title="Invite guest to record"
      onClose={() => setShowInviteLinkModal(false)}
      className={styles.inviteGuestModal}
    >
      <p className="text__body__regular__medium__textNeutral30">
        Here’s your record link! We’ve already sent it to the guest via email.
        But you can copy it and share it with them through other means as well.
        This link will be active for 7 days.
      </p>
      <div className={styles.linkComponent}>
        <div
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            styles.guestUrl,
          )}
        >
          {showTextEllipsis(
            invitedGuest!.album_url,
            !isMobile ? 65 : invitedGuest!.album_url.length,
          )}
        </div>
        <Button
          className={styles.copyLinkButton}
          buttonStyle={ButtonStyle.PrimaryFilled}
          buttonSize={ButtonSize.Small}
          onClick={onCopyLink}
        >
          Copy link
        </Button>
      </div>
    </Modal>
  );

  const showMobileLayout = () => (
    <div className={styles.cameraSection}>
      <CameraUpload
        album={album}
        selectedQuestion={state.selectedQuestion}
        notifySelectQuestion={onSelectQuestion}
        notifySelectCustomRecording={onSelectCustomRecording}
        notifyShowInviteModal={() => setShowInviteGuestModal(true)}
        refetchAlbum={refetchAlbum}
        upgradeAction={upgradeAction}
      />
    </div>
  );

  const showSignUpModal = () => (
    <ModalWarning
      title="Sign up to save your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            You’ve run out of free trial clips! Please complete the sign up
            process to be able to save your clips and access them later,
            download or share them, as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  useEffect(() => {
    selectTheNextQuestion();
  }, [album.unansweredQuestions?.length]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        isMobile &&
        document.visibilityState === 'hidden' &&
        state.isRecording
      ) {
        dispatch({
          type: selfRecordingActions.cleanState,
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isMobile, state.isRecording]);

  return (
    <div className={styles.allPage}>
      {showInviteGuestModal && showInviteGuestModalComponent()}
      {showModalSignUp && showSignUpModal()}
      {showInviteLinkModal &&
        invitedGuest?.album_url &&
        showInviteLinkModalComponent()}
      {isMobile ? (
        showMobileLayout()
      ) : (
        <>
          <div className={styles.questionsSection}>
            <div className={styles.questionsHeader}>
              <p className={styles.questionsTitle}>Select a question</p>
              <div className={styles.inviteGuest}>
                <p className="text__body__regular__overline__textNeutral30">
                  OR
                </p>
                <Button
                  className={styles.inviteButton}
                  buttonSize={ButtonSize.Small}
                  buttonType={ButtonType.Button}
                  buttonStyle={ButtonStyle.PrimaryStroke}
                  onClick={() => setShowInviteGuestModal(true)}
                  disabled={
                    state.isRecording ||
                    showWarningLimitCondition ||
                    !user.permissions?.permissions.inviteGuest
                  }
                  tooltipContent="Available after sign up"
                  tooltip={!user.permissions?.permissions.inviteGuest}
                  tooltipPosition="top"
                  tooltipId="invite-disabled-tooltip"
                >
                  <div className={styles.inviteButtonContent}>
                    <LinkIcon />
                    Invite guest(s)
                  </div>
                </Button>
              </div>
            </div>
            <div className={styles.lineSeparator} />
            <QuestionsSelector
              album={album}
              selectedQuestion={state.selectedQuestion}
              onSelectQuestion={onSelectQuestion}
              isCustomRecording={isCustomRecording}
              onSelectCustomRecording={onSelectCustomRecording}
            />
          </div>
          <div className={styles.cameraSection}>
            {showWarningLimitCondition &&
              userPermissionUpgrade(user) &&
              showWarningAlbumLimitedCondition()}
            <CameraUpload
              album={album}
              selectedQuestion={state.selectedQuestion}
              notifySelectQuestion={onSelectQuestion}
              notifySelectCustomRecording={onSelectCustomRecording}
              notifyShowInviteModal={() => setShowInviteGuestModal(true)}
              upgradeAction={upgradeAction}
              refetchAlbum={refetchAlbum}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { RecordUpload };
