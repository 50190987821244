import { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'assets/images/logo-gray.svg';
import styles from './video-call-ended.module.scss';

type VideoCallEndedProps = {
  notifyClose: () => void;
};

const VideoCallEnded: React.FC<VideoCallEndedProps> = ({ notifyClose }) => {
  const [countdown, setCountdown] = useState(5);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevValue) => prevValue - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      notifyClose();
    }
  }, [countdown]);

  return (
    <div className={styles.container}>
      <Logo />
      <div className="text__heading4__textNeutral00">
        Video call time has run out.
      </div>
      <div className="text__body__regular__large__textNeutral20">
        Your recordings will be saved and you will be redirected to your album
        in: {countdown} seconds.
      </div>
    </div>
  );
};

export { VideoCallEnded };
