/* eslint-disable max-len */
import { classnames } from 'helpers/utils';
import { useEffect, useRef, useState } from 'react';
import { VideoCallMenuQuestionsTabs } from 'common/enums';
import { captureException } from 'sentry';
import { IAgoraRTCRemoteUser } from 'agora-rtc-react';
import { Header } from './components/header';
import { UnansweredQuestions } from './components/unanswered-questions';
import { AnsweredQuestions } from './components/answered-questions';
import { AlertTimeRunOut } from './components/alert-time-run-out';
import { AlertLongRecordingRunOut } from './components/alert-long-recording-run-out';
import { Footer } from './components/footer';
import styles from './owner-menu.module.scss';

type OwnerMenuProps = {
  unanswered?: ClipType[];
  answered: ClipType[];
  newAnsweredQuestions: ClipType[];
  questionSelected: ClipType;
  noAbleToStartRecording: boolean;
  clipLimitReached: boolean;
  albumQuestionsPermissionLimit: number;
  clipsCountRecorded: number;
  maxNumberRecordingChunks: number;
  forceStopRecording: boolean;
  otherUserInCall: boolean;
  otherUserTracks: IAgoraRTCRemoteUser | null;
  otherUserTrackState: TrackStateType;
  notifyRecordAction: (
    newValue: boolean,
    manualStop?: boolean,
  ) => Promise<void>;
  notifyQuestionsSelected: (questionSelected: ClipType) => void;
  notifyErrorRecording: (message: string) => void;
  notifyIncrementClipCountRecorded: () => void;
  notifyResetClipCountRecorded: () => void;
  otherUserAudioFailed: boolean;
};

const OwnerMenu: React.FC<OwnerMenuProps> = ({
  unanswered = [],
  answered,
  newAnsweredQuestions,
  questionSelected,
  noAbleToStartRecording,
  clipLimitReached,
  albumQuestionsPermissionLimit,
  maxNumberRecordingChunks,
  clipsCountRecorded,
  forceStopRecording,
  otherUserInCall,
  otherUserTracks,
  otherUserTrackState,
  notifyRecordAction,
  notifyQuestionsSelected,
  notifyErrorRecording,
  notifyIncrementClipCountRecorded,
  notifyResetClipCountRecorded,
  otherUserAudioFailed,
}) => {
  const [tabSelected, setTabSelected] = useState<VideoCallMenuQuestionsTabs>(
    VideoCallMenuQuestionsTabs.pending,
  );
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(true);
  const [keepRecordingToggleValue, setKeepRecordingToggleValue] =
    useState<boolean>(true);
  const [istimeToShowAlertToggle, setIstimeToShowAlertToggle] =
    useState<boolean>(false);
  const [istimeToShowLongVideoAlert, setIstimeToShowLongVideoAlert] =
    useState<boolean>(false);

  const [stoppingRecording, setStoppingRecording] = useState<boolean>(false);
  const [startingRecording, setStartingRecording] = useState<boolean>(false);

  const stopRecordingIntents = useRef<number>(0);
  const [endpointsNotWorking, setEndpointsNotWorking] =
    useState<boolean>(false);

  useEffect(() => {
    if (clipsCountRecorded === maxNumberRecordingChunks) {
      setKeepRecordingToggleValue(false);
    }
  }, [clipsCountRecorded]);

  useEffect(() => {
    if (forceStopRecording) {
      setIsRecording(false);
    }
  }, [forceStopRecording]);

  const handleTabSelected = (tabName: VideoCallMenuQuestionsTabs) => {
    setTabSelected(tabName);
  };

  const handleRecordingAction = async (
    startRecording: boolean,
    manualStop?: boolean,
  ) => {
    try {
      if (startRecording) {
        // Start executed
        setStartingRecording(true);
        await notifyRecordAction(startRecording, manualStop);
        setIsRecording(true);
        setStartingRecording(false);
      } else {
        // Stop executed
        setIsRecording(false);
        setStoppingRecording(true);
        await notifyRecordAction(startRecording, manualStop);
        setStoppingRecording(false);
        stopRecordingIntents.current = 0;
        setKeepRecordingToggleValue(true);
      }
    } catch (err) {
      captureException(err);
      if (startRecording) {
        // Start executed
        notifyErrorRecording('There was an error trying to start recording.');
        setStartingRecording(false);
      } else if (stopRecordingIntents.current === 3) {
        // Stop executed
        notifyErrorRecording(
          'Stop recording is not responding. Please leave the call and try again later.',
        );
        setStoppingRecording(false);
        setIsRecording(false);
        setEndpointsNotWorking(true);
      } else {
        stopRecordingIntents.current += 1;
        handleRecordingAction(false, manualStop);
      }
    }
  };

  const showHeader = () => (
    <div className={styles.header}>
      <Header
        isMenuExpanded={isMenuExpanded}
        tabSelected={tabSelected}
        notifyMenuChange={() =>
          setIsMenuExpanded((prevState: boolean) => !prevState)
        }
        notifyTabSelected={(tab: VideoCallMenuQuestionsTabs) =>
          handleTabSelected(tab)
        }
      />
    </div>
  );

  const showQuestionsSection = () => (
    <div
      className={classnames(
        styles.questionsList,
        isMenuExpanded ? styles.questionsExpanded : styles.questionsCollapsed,
        'text__body__regular__medium__textNeutral10',
      )}
    >
      {tabSelected === VideoCallMenuQuestionsTabs.pending ? (
        <UnansweredQuestions
          clipLimitReached={clipLimitReached}
          disableSelection={
            isRecording ||
            stoppingRecording ||
            startingRecording ||
            endpointsNotWorking
          }
          unansweredQuestions={unanswered}
          answeredQuestionsAmount={
            answered.length + newAnsweredQuestions.length
          }
          questionSelected={questionSelected}
          albumQuestionsPermissionLimit={albumQuestionsPermissionLimit}
          notifyQuestionsSelected={(question: ClipType) =>
            notifyQuestionsSelected(question)
          }
        />
      ) : (
        <AnsweredQuestions
          previouslyAnsweredQuestions={answered}
          newAnsweredQuestions={newAnsweredQuestions}
        />
      )}
    </div>
  );

  const showFooter = () => (
    <div className={styles.footer}>
      <Footer
        isRecording={isRecording}
        keepRecordingToggleValue={keepRecordingToggleValue}
        tabSelected={tabSelected}
        noAbleToStartRecording={noAbleToStartRecording || !otherUserInCall}
        stoppingRecording={stoppingRecording}
        startingRecording={startingRecording}
        endpointsNotWorking={endpointsNotWorking}
        clipsCountRecorded={clipsCountRecorded}
        maxNumberRecordingChunks={maxNumberRecordingChunks}
        istimeToShowAlertToggle={istimeToShowAlertToggle}
        istimeToShowLongVideoAlert={istimeToShowLongVideoAlert}
        otherUserInCall={otherUserInCall}
        otherUserTracks={otherUserTracks}
        otherUserTrackState={otherUserTrackState}
        notifyChangeIsRecording={handleRecordingAction}
        notifyShowAlertTimeRunOutToggle={(show: boolean) => {
          setIstimeToShowAlertToggle(show);
        }}
        notifyShowAlertTimeRunOutLongVideo={(show: boolean) => {
          setIstimeToShowLongVideoAlert(show);
        }}
        notifyErrorRecording={(message: string) =>
          notifyErrorRecording(message)
        }
        notifyIncrementClipCountRecorded={() =>
          notifyIncrementClipCountRecorded()
        }
        notifyResetClipCountRecorded={() => notifyResetClipCountRecorded()}
        otherUserAudioFailed={otherUserAudioFailed}
      />
    </div>
  );

  // ! Not use for now while we have only one video of 30 min.
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const showAlertTimeRunOutToggleComponent = () => (
    <div className={styles.alertTimeRunsOutToggle}>
      <AlertTimeRunOut
        keepRecordingToggle={keepRecordingToggleValue}
        notifyNewToggleValue={() =>
          setKeepRecordingToggleValue(!keepRecordingToggleValue)
        }
      />
    </div>
  );

  const showLongVideoAlertComponent = () => (
    <div className={styles.alertLongVideo}>
      <AlertLongRecordingRunOut />
    </div>
  );

  return (
    <div className={classnames(styles.menuAndAlertsContainer)}>
      {/* ! Not use for now while we have only one video of 30 min. */}
      {/* {istimeToShowAlertToggle && showAlertTimeRunOutToggleComponent()} */}

      {istimeToShowLongVideoAlert && showLongVideoAlertComponent()}

      <div
        className={classnames(
          styles.menuContainer,
          isMenuExpanded ? styles.menuExpanded : styles.menuCollapsed,
        )}
      >
        {showHeader()}
        {showQuestionsSection()}
        {showFooter()}
      </div>
    </div>
  );
};

export { OwnerMenu };
