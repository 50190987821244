import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { FileExtension } from 'common/enums';
import { classnames } from 'helpers/utils';
import { HelperText } from 'common/helper-text';
import { useFileUpload } from 'hooks/use-file-upload';
import {
  bytesToMegabytes,
  formatFileExtensions,
  getFileExtension,
  isSupportedExtension,
  isValidSize,
} from 'helpers/file-utils';
import { FileUploaderErrorMessage } from 'common/file-uploader-error-message';
import styles from './file-uploader.module.scss';

type FileUploaderProps = {
  className?: string;
  supportedExtensions?: Array<FileExtension>;
  maxFileSizeInMb?: number;
  uploadingMessage: string;
  onLoading: Function;
  onSuccess: Function;
  onError: Function;
  onCancel: Function;
  message?: string;
  onFileNameChange?: Function;
};

const FileUploader: React.FC<FileUploaderProps> = ({
  className = '',
  supportedExtensions = [],
  maxFileSizeInMb = 0,
  onLoading,
  onSuccess,
  onError,
  onCancel,
  uploadingMessage = '',
  message = 'Drag and drop your album cover photo or',
  onFileNameChange,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const classes = [className, styles.uploader];

  const [currentFile, setCurrentFile] = useState<File | null>();
  const [hasError, setHasError] = useState<Boolean>(false);
  const [isUploading, setIsUploading] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { uploadS3File, cancelRequest, uploadPercentage, response, error } =
    useFileUpload();

  const uploadErrors = {
    generic: 'Oh no, something went wrong.',
    size: `File is too big. Maximum file size accepted is ${maxFileSizeInMb}MB.`,
    format: `File format not supported. Please upload only ${formatFileExtensions(
      supportedExtensions,
    ).toUpperCase()} files.`,
  };

  useEffect(() => {
    if (error) {
      onError();
      setIsUploading(false);
      setHasError(true);
      setErrorMessage(uploadErrors.generic);
    }
  }, [error]);

  useEffect(() => {
    if (response && isUploading) {
      setIsUploading(false);
      onSuccess(response);
      if (onFileNameChange) {
        onFileNameChange(currentFile?.name);
      }
    }
  }, [response]);

  const isValidFile = (file: File) => {
    const fileExtension = getFileExtension(file.name);
    if (
      !isSupportedExtension(
        fileExtension.toLocaleLowerCase(),
        supportedExtensions,
      )
    ) {
      setHasError(true);
      setErrorMessage(uploadErrors.format);
      return false;
    }
    if (!isValidSize(maxFileSizeInMb, bytesToMegabytes(file.size))) {
      setHasError(true);
      setErrorMessage(uploadErrors.size);
      return false;
    }
    return true;
  };

  const handleFileUpload = (file: File) => {
    if (isValidFile(file!)) {
      setHasError(false);
      setErrorMessage('');
      setCurrentFile(file!);
      onLoading();
      setIsUploading(true);
      uploadS3File(file!);
      if (onFileNameChange) {
        onFileNameChange(file.name);
      }
    }
  };

  const retryUpload = (file: File) => {
    setHasError(false);
    onLoading();
    setIsUploading(true);
    uploadS3File(file!);
  };

  const clearFile = () => {
    setHasError(false);
    setIsUploading(false);
    setCurrentFile(null);
    if (onFileNameChange) {
      onFileNameChange(null);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.items[0]?.getAsFile();
    if (isValidFile(file!)) {
      setCurrentFile(file!);
      handleFileUpload(file!);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (isValidFile(file!)) {
      setCurrentFile(file!);
      handleFileUpload(file!);
    }
  };

  const handleRequestCancel = () => {
    cancelRequest();
    onCancel();
    setIsUploading(false);
  };

  const showProgress = () => (
    <>
      <HelperText
        content={uploadingMessage}
        className={styles.progressMessage}
      />
      <div className={styles.loadingBar}>
        <div
          className={styles.progress}
          style={{ width: `${uploadPercentage}%` }}
        />
      </div>
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        buttonSize={ButtonSize.Tiny}
        className={styles.progressButton}
        onClick={handleRequestCancel}
      >
        Cancel
      </Button>
    </>
  );

  const showUploader = () => {
    const uploaderClasses = [
      styles.uploaderText,
      'text__body__regular__small__textNeutral30',
    ];

    return (
      <>
        <span className={classnames(...uploaderClasses)}>{message}</span>
        <Button
          buttonStyle={ButtonStyle.PrimaryStroke}
          onClick={() => fileRef.current?.click()}
        >
          Browse files
        </Button>
        <input
          type="file"
          ref={fileRef}
          accept={formatFileExtensions(supportedExtensions)}
          onChange={handleInputChange}
          hidden
        />
      </>
    );
  };

  const showBrowseFiles = () => (
    <>
      <Button
        key={uploadErrors.format}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => fileRef.current?.click()}
      >
        Browse files
      </Button>
      <input
        type="file"
        ref={fileRef}
        accept={formatFileExtensions(supportedExtensions)}
        onChange={handleInputChange}
        hidden
      />
    </>
  );

  const showRetryOptions = () => (
    <div key="retry">
      <Button
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => retryUpload(currentFile!)}
      >
        Retry
      </Button>
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        onClick={() => clearFile()}
      >
        Delete cover
      </Button>
    </div>
  );

  const getActions = () =>
    errorMessage === uploadErrors.generic
      ? showRetryOptions()
      : showBrowseFiles();

  const getContent = () => {
    if (hasError) {
      return (
        <FileUploaderErrorMessage
          errorMessage={errorMessage}
          actions={[getActions()]}
          key={errorMessage}
        />
      );
    }

    return isUploading ? showProgress() : showUploader();
  };

  return (
    <div
      className={classnames(...classes)}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
    >
      {getContent()}
    </div>
  );
};

export { FileUploader };
