import { Dispatch, ReactNode, createContext, useMemo, useReducer } from 'react';
import {
  StoreStateType,
  StoreReducer,
  StoreInitialState,
} from './store-reducer';

type StoreProviderProps = {
  children: ReactNode;
};

const StoreContext = createContext<{
  state: StoreStateType;
  dispatch: Dispatch<any>;
}>({
  state: StoreInitialState,
  dispatch: () => null,
});

const StoreProvider = ({ children }: StoreProviderProps) => {
  const [state, dispatch] = useReducer(StoreReducer, StoreInitialState);
  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <StoreContext.Provider value={providerValue}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
