/* eslint-disable no-restricted-syntax */
import { GroupedClips } from 'common/grouped-clips';
import { ClipActions } from 'common/enums';
import { InfoIcon } from 'assets/icons';
import { anyClipInProcess } from 'helpers/utils';
import styles from './all-clips-list.module.scss';

type AllClipsListProps = {
  album: AlbumType;
  clipSelected: ClipType;
  publicView?: boolean;
  notifyClipAction: (
    action: ClipActions,
    clip: ClipType,
    extraData?: ClipExtraDataType,
  ) => void;
  notifyStopClip?: () => void;
  notifyChangeClipOrder?: (clips: ClipType[]) => void;
};

const AllClipsList: React.FC<AllClipsListProps> = ({
  album,
  clipSelected,
  publicView = false,
  notifyClipAction,
  notifyStopClip,
  notifyChangeClipOrder,
}) => {
  const showClipsInProcessComponent = () => (
    <div className={styles.clipsInProcessContainer}>
      <div className={styles.iconColumn}>
        <div className={styles.alertIcon}>
          <InfoIcon />
        </div>
      </div>
      <div className={styles.textColumn}>
        <div className="text__body__regular__large__textNeutral50">
          Your video call is being processed
        </div>
        <div className="text__body__regular__medium__textNeutral40">
          You will be able to see and modify your clips once they finish
          uploading.
        </div>
      </div>
    </div>
  );

  const showList = () => (
    <GroupedClips
      publicView={publicView}
      album={album}
      clipSelected={clipSelected}
      notifyClipAction={(
        action: ClipActions,
        clip: ClipType,
        extraData?: ClipExtraDataType,
      ) => {
        notifyClipAction(action, clip, extraData);
      }}
      notifyStopClip={() => notifyStopClip!()}
      notifyChangeClipOrder={notifyChangeClipOrder}
    />
  );

  return (
    <div className={styles.clipsScrollBox}>
      {anyClipInProcess(album.unansweredQuestions) &&
        showClipsInProcessComponent()}
      <div className={styles.clipsScrollBoxContent}>{showList()}</div>
    </div>
  );
};

export { AllClipsList };
