import { Modal } from 'common/modal/modal';
import { Button } from 'common/button';
import styles from './modal-other-user-audio-failed.module.scss';

type ModalOtherUserAudioFailedType = {
  notifyOnClose: () => void;
};

const ModalOtherUserAudioFailed: React.FC<ModalOtherUserAudioFailedType> = ({
  notifyOnClose,
}) => (
  <Modal disableManualClosing contentClassName={styles.modal} title="Error">
    <div className={styles.modalContent}>
      <p className="text__body__regular__medium__textNeutral40">
        We are unable to record the audio of the other person in the call. Ask
        them to join again, or if the problem persists leave the call and create
        a new one.
      </p>
      <Button className={styles.okButton} onClick={notifyOnClose}>
        Ok
      </Button>
    </div>
  </Modal>
);

export { ModalOtherUserAudioFailed };
