/* eslint-disable max-len */
import { useState, useEffect, useRef, useContext } from 'react';
import AgoraRTC, {
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-react';

import { AgoraController } from 'networking/controllers/agora-controller';
import {
  canUpgradeAlbum,
  capitalizeFirstLetter,
  classnames,
  getPermissionsByAlbum,
  isFreeTrial,
  isOwner,
  isTemporaryUser,
  sleep,
  totalAlbumQuestions,
  userPermissionUpgrade,
} from 'helpers/utils';
import {
  NotificationType,
  PaymentType,
  RecordingStatus,
  SubscriptionSchemeId,
} from 'common/enums';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { cloneDeep } from 'lodash';
import {
  useClient,
  agoraOptions,
  msToHideClipRecordedWindow,
  questionsMaxLength,
  sAnnualWarningTimeVideoCall,
  msToRecallPingToGetVideoCallUsedTime,
  msMaxRecordingPeriodTime,
  msMaxClipChunkDuration,
  apiBaseURL,
} from 'config/constants';
import { Clip } from 'models/clip';
import { Notification } from 'common/notification';
import {
  VideoCallRole,
  useVideocallWebsocket,
} from 'hooks/use-videocall-websocket';
import { SensorToggleButton } from 'common/sensor-toggle-button';
import { AppContext, SensorSelectionContext, appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { AllPaymentProcess } from 'common/all-payment-process';
import { ModalBuyTimeSubscription } from 'common/modal-clips-time-subscription';
import { CopyLink } from 'common/copy-link';
import { useLoadSensorSelection } from 'hooks/use-load-sensor-selection';
import { LinkIcon, VideoIcon } from 'assets/icons';
import { BuyExtraCallTimeProcess } from 'common/buy-extra-call-time-process';
import { BuyClipsProcess } from 'common/buy-clips-process';
import {
  getOtherRole,
  getUserRole,
  isRoleChanged,
  userHasRole,
  videocallIsFull,
} from 'helpers/videocall-helper';
import { Select } from 'common/select';
import { ModalWarning } from 'common/modal-warning';
import { goToPage, RouteName } from 'routes';
import { Video } from './components/video';
import { OwnerMenu } from './components/owner-menu';
import { ClipRecordedWindow } from './components/clip-recorded-window';
import { DeleteConfirmationWindow } from './components/delete-confirmation-window';
import { WarningReachingTimeLimit } from './components/warning-reaching-time-limit';
import { WarningReachingClipsLimit } from './components/warning-reaching-clips-limit';
import { WarningUserLeftCall } from './components/warning-user-left-call';
import styles from './video-call.module.scss';
import { WarningRoleUpdated } from './components/warning-role-updated';
import { ModalConnectionFailed } from './components/modal-connection-failed';
import { ModalRoleChanging } from './components/modal-role-changing';
import { ModalOtherUserAudioFailed } from './components/modal-other-user-audio-failed';

export type PoolContainerType = {
  clipMain: ClipType | null;
  clipsExtras: ClipType[];
};

type BackUpQuestionSelectedType = {
  index: number;
  backUpQuestion: ClipType | null;
};

type VideoCallProps = {
  id: string;
  album: AlbumType;
  user: UserType;
  subSchemes: SubscriptionTypeType[];
  notifyAlbumChange: (album: AlbumType) => void;
  notifyEndCall: (limitReached?: boolean) => Promise<void>;
  refetchAlbum: () => Promise<void>;
  initialRole: VideoCallRole;
};

const VideoCall: React.FC<VideoCallProps> = ({
  album,
  user,
  subSchemes,
  notifyAlbumChange,
  notifyEndCall,
  refetchAlbum,
  initialRole,
}) => {
  const { dispatch } = useContext(AppContext);

  const [published, setPublished] = useState<boolean>(false);
  const [token, setToken] = useState('');

  const [otherUserInCall, setOtherUserInCall] = useState<boolean>(false);
  const [otherUserTracks, setOtherUserTracks] =
    useState<IAgoraRTCRemoteUser | null>(null);
  const [otherUserTrackState, setOtherUserTrackState] = useState({
    audio: false,
    video: false,
  });
  const [otherUserAudioFailed, setOtherUserAudioFailed] = useState(false);
  const [showOtherUserAudioFailed, setShowOtherUserAudioFailed] =
    useState(false);

  const [myTracks, setMyTracks] =
    useState<[IMicrophoneAudioTrack, ICameraVideoTrack | undefined]>();

  const [newAnsweredQuestions, setNewAnsweredQuestions] = useState<ClipType[]>(
    [],
  );
  const [questionSelected, setQuestionSelected] = useState<ClipType>(
    new Clip(),
  );

  const [showModalBuyClips, setShowModalBuyClips] = useState<boolean>(false);

  const [startPaymentProcess, setStartPaymentProcess] = useState(false);

  const [endCallWarning, setEndCallWarning] = useState<boolean>(false);

  const [showModalBuyClipsOrTime, setShowModalBuyClipsOrTime] =
    useState<boolean>(false);

  const [showModalBuyTime, setShowModalBuyExtraTime] = useState<boolean>(false);

  const [showClipRecordedWindow, setShowClipRecordedWindow] =
    useState<boolean>(false);
  const [displayClipRecordedWindow, setDisplayClipRecordedWindow] =
    useState<boolean>(false);
  const [allClipsCount, setAllClipsCount] = useState<number>(0);
  const [clipsCountRecorded, setClipsCountRecorded] = useState<number>(1);

  const [forceStopRecording, setForceStopRecording] = useState<boolean>(false);

  const [showWarningReachingClipLimit, setShowWarningReachingClipLimit] =
    useState<boolean>(false);
  const [userLeftCall, setUserLeftCall] = useState<VideoCallRole | undefined>(
    undefined,
  );

  const [showWarningReachingTimeLimit, setShowWarningReachingTimeLimit] =
    useState<boolean>(false);
  const [sCallTimeLeft, setSCallTimeLeft] = useState<number>(
    album.videoCallProps?.maxCallTime && album.videoCallProps.usedCallTime
      ? album.videoCallProps.maxCallTime - album.videoCallProps.usedCallTime
      : 1000,
  );

  const [showDeleteConfirmationWindow, setShowDeleteConfirmationWindow] =
    useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [showRoleSwitched, setShowRoleSwitched] = useState<boolean>(false);

  const [showModalConnectionFailed, setShowModalConnectionFailed] =
    useState<boolean>(false);

  const [changeRoleLoading, setChangeRoleLoading] = useState<boolean>(false);

  const [signUpModal, setShowModalSignUp] = useState<boolean>(false);

  const channelName = useRef<string>('');

  const role = useRef<VideoCallRole | undefined>(undefined);

  const initialRoleSet = useRef<boolean>(false);

  const waitForRequest = useRef<boolean>(true);

  const sid = useRef<string>('');
  const resourceId = useRef<string>('');

  const [albumPermissions, setAlbumPermissions] = useState<
    SubscriptionPermissionsType | undefined
  >();

  const interviewerIsRecording = useRef<boolean>(false);
  const stoppingRecording = useRef<boolean>(false);

  const poolContainer = useRef<PoolContainerType>({
    clipMain: null,
    clipsExtras: [],
  });
  const videoPartNumber = useRef<number>(2);
  const backupQuestionSelected = useRef<BackUpQuestionSelectedType>({
    index: -1,
    backUpQuestion: null,
  });
  const maxNumberRecordingChunks = useRef<number>(
    msMaxRecordingPeriodTime / msMaxClipChunkDuration,
  );

  // Secure constant token associated with album
  const videoCallToken = album.videoCallProps?.videoCallToken!;
  const {
    online,
    sendRecordingStarted,
    sendRecordingEnded,
    recordingInProgress,
    updateRole,
    callInformation,
    socketDisconnected,
    getCallInformation,
  } = useVideocallWebsocket(album?.id || null, videoCallToken);

  // Get the call information
  useEffect(() => {
    if (online && !callInformation) {
      getCallInformation();
    }
  }, [online, callInformation]);

  // Call information arrives and need to react to a role changed
  useEffect(() => {
    if (
      waitForRequest.current &&
      callInformation &&
      role.current &&
      isRoleChanged(role.current, user.id, callInformation)
    ) {
      setChangeRoleLoading(true);
      // Wait 1 second before update role
      setTimeout(() => {
        waitForRequest.current = false;
        // I need to ask for call information again to be able to run the code
        // that's responsible to update the role, which is in the useEffect below
        getCallInformation();
      }, 2000);
    }
  }, [callInformation]);

  useEffect(() => {
    if (isTemporaryUser(user) && album.clipAmount === album.maxClips) {
      setShowModalSignUp(true);
    }
  }, []);

  // Update the role after waiting for make the request
  useEffect(() => {
    if (
      !waitForRequest.current &&
      callInformation &&
      role.current &&
      isRoleChanged(role.current, user.id, callInformation)
    ) {
      const newRole = getOtherRole(role.current!);
      role.current = newRole;
      updateRole(newRole);
      setChangeRoleLoading(false);
      setShowRoleSwitched(true);
      if (newRole === VideoCallRole.INTERVIEWER) {
        refetchAlbum();
      }
      waitForRequest.current = true;
    }
  }, [callInformation]);

  // Check if is able to entering the call and set the initial role
  useEffect(() => {
    if (
      callInformation &&
      !initialRoleSet.current &&
      !userHasRole(callInformation, user.id)
    ) {
      if (videocallIsFull(callInformation)) {
        notifyEndCall();
      } else {
        updateRole(initialRole);
        initialRoleSet.current = true;
      }
    }
  }, [callInformation]);

  // Save initial role after setting it
  useEffect(() => {
    if (
      callInformation &&
      !role.current &&
      userHasRole(callInformation, user.id)
    ) {
      role.current = getUserRole(callInformation, user.id);
    }
  }, [callInformation]);

  // If the web socket connection fails
  useEffect(() => {
    if (socketDisconnected) {
      setShowModalConnectionFailed(true);
    }
  }, [socketDisconnected]);

  const client = useClient();

  const { sensorSelection, updateSensorSelection } = useContext(
    SensorSelectionContext,
  );
  useLoadSensorSelection();

  const createAgoraTracks = async () => {
    let myAudio: IMicrophoneAudioTrack;
    let myVideo: ICameraVideoTrack | undefined;

    try {
      // "{ encoderConfig: 'music_standard' } by default.
      myAudio = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: sensorSelection.microphone.selectedOption?.deviceId,
      });
    } catch (e) {
      notifyEndCall();
      return;
    }

    try {
      //  640 x 480 by default
      myVideo = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '1080p',
        cameraId: sensorSelection.camera.selectedOption?.deviceId,
      });
    } catch (e) {
      updateSensorSelection({ type: 'CAMERA_TOGGLED', enabled: false });
    }

    setMyTracks([myAudio, myVideo]);
  };

  const leaveChannel = async (limitReached?: boolean) => {
    if (myTracks) {
      if (myTracks[0]) {
        myTracks[0].close();
      }

      if (myTracks[1]) {
        myTracks[1].close();
      }
    }

    await client.leave();
    client.removeAllListeners();

    setPublished(false);

    await notifyEndCall(!!limitReached);
  };

  const addCustomItemToUnansweredList = (alb: AlbumType) => {
    const customItem = new Clip();
    customItem.recordingStatus = RecordingStatus.noVideo;
    customItem.name = 'Custom recording';

    const totalQuestions = totalAlbumQuestions(alb);

    if (totalQuestions >= album.maxClips) {
      customItem.name = 'Custom recording (Reached limit)';
    }

    let unansweredListCopy = cloneDeep(alb.unansweredQuestions)?.filter(
      (clip) => clip.recordingStatus !== 'processing',
    );

    if (unansweredListCopy?.length) {
      unansweredListCopy.unshift(customItem);
    } else {
      unansweredListCopy = [customItem];
    }

    return { ...alb, unansweredQuestions: unansweredListCopy };
  };

  const calculateCallTimeLeft = ({
    usedCallTime,
    maxCallTime,
  }: AgoraVideoCallPropsType) => {
    const secondTimeLeft = maxCallTime! - +usedCallTime!;

    setSCallTimeLeft(secondTimeLeft);
  };

  const setInterviewerData = async (albumResponse: AlbumType) => {
    try {
      const permissionsObject = getPermissionsByAlbum(
        subSchemes,
        albumResponse,
      );
      if (!albumPermissions) {
        setAlbumPermissions(permissionsObject);
      }

      if (!allClipsCount) {
        setAllClipsCount(albumResponse.clipAmount);
      }

      let albumUpdated = albumResponse;
      if (!albumUpdated.unansweredQuestions?.some((clip) => clip.id === -1)) {
        albumUpdated = addCustomItemToUnansweredList(albumResponse);
        notifyAlbumChange(albumUpdated);
      }

      const { unansweredQuestions } = albumUpdated;

      setQuestionSelected(
        unansweredQuestions!.length === 1
          ? unansweredQuestions![0]
          : unansweredQuestions![1],
      );

      if (!sCallTimeLeft) {
        calculateCallTimeLeft(albumResponse.videoCallProps!);
      }
    } catch (err) {
      leaveChannel();
    }
  };

  // Update microphone and camera devices
  useEffect(() => {
    if (myTracks && published) {
      if (myTracks[0] && sensorSelection.microphone.selectedOption?.deviceId) {
        myTracks[0].setDevice(
          sensorSelection.microphone.selectedOption?.deviceId,
        );
      }
      if (myTracks[1] && sensorSelection.camera.selectedOption?.deviceId) {
        myTracks[1].setDevice(sensorSelection.camera.selectedOption?.deviceId);
      }
    }
  }, [
    myTracks,
    published,
    sensorSelection.camera.selectedOption?.deviceId,
    sensorSelection.microphone.selectedOption?.deviceId,
  ]);

  // Enable/Disable microphone and camera
  useEffect(() => {
    // Don't disable anything if the tracks aren't published yet
    // Disabled tracks cannot be published
    if (myTracks && published) {
      if (myTracks[0]) {
        myTracks[0].setEnabled(sensorSelection.microphone.enabled);
      }
      if (myTracks[1]) {
        myTracks[1].setEnabled(sensorSelection.camera.enabled);
      }
    }
  }, [
    myTracks,
    published,
    sensorSelection.camera.enabled,
    sensorSelection.microphone.enabled,
  ]);

  const getAlbumAndSetData = async (userRole: VideoCallRole) => {
    if (userRole === VideoCallRole.INTERVIEWER) {
      setInterviewerData(album);
    }
  };

  useEffect(() => {
    client.setClientRole(agoraOptions.role);
    createAgoraTracks();
  }, []);

  useEffect(() => {
    getAlbumAndSetData(role.current ?? initialRole);
  }, [album]);

  useEffect(() => {
    if (album.maxClips > 0) {
      if (allClipsCount >= album.maxClips - 3) {
        setShowWarningReachingClipLimit(true);
      } else {
        setShowWarningReachingClipLimit(false);
      }
    }
  }, [allClipsCount, albumPermissions]);

  const updateTimeLeftFromPing = async () => {
    const response = await AgoraController.getNewVideoCallUsedTime(album!.id);
    const secondTimeLeft =
      (album.videoCallProps?.maxCallTime || albumPermissions!.usersCallTime) -
      response.usedCallTime;

    setSCallTimeLeft(secondTimeLeft);
  };

  useEffect(() => {
    let timeLeftPing: NodeJS.Timer;
    let timeLeftFront: NodeJS.Timer;

    if (otherUserInCall && role.current === VideoCallRole.INTERVIEWER) {
      timeLeftPing = setInterval(() => {
        updateTimeLeftFromPing();
      }, msToRecallPingToGetVideoCallUsedTime);

      timeLeftFront = setInterval(() => {
        setSCallTimeLeft((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timeLeftFront);
      clearInterval(timeLeftPing);
    };
  }, [otherUserInCall]);

  const hideSmooth = () => {
    setDisplayClipRecordedWindow(false);
    setTimeout(() => setShowClipRecordedWindow(false), 800);
  };

  const cleanPoolUpdateVideoPartNumber = () => {
    videoPartNumber.current = 2;
    poolContainer.current = {
      clipMain: null,
      clipsExtras: [],
    };
  };

  const hideFast = () => {
    setDisplayClipRecordedWindow(false);
    setShowClipRecordedWindow(false);
    cleanPoolUpdateVideoPartNumber();
  };

  useEffect(() => {
    let timer: NodeJS.Timer;

    if (showClipRecordedWindow) {
      timer = setInterval(() => {
        hideSmooth();
        cleanPoolUpdateVideoPartNumber();
      }, msToHideClipRecordedWindow);
    }

    return () => {
      clearInterval(timer);
    };
  }, [showClipRecordedWindow]);

  const fetchToken = () => AgoraController.getAgoraToken(videoCallToken);

  const handleErrorRecording = (message: string) => {
    setErrorMessage(message);
    setShowError(true);
  };

  useEffect(() => {
    const publishClient = async () => {
      //  ----------------LISTENERS -----------------------------------------
      client.on('user-joined', async (newGuest) => {
        setOtherUserInCall(true);
        setUserLeftCall(undefined);
        setOtherUserTracks(newGuest);
      });

      client.on('user-published', async (newGuest, mediaType) => {
        await client.subscribe(newGuest, mediaType);

        setOtherUserTracks(newGuest);

        if (mediaType === 'video') {
          setOtherUserTrackState((ps) => ({ ...ps, video: !ps.video }));
        }
        if (mediaType === 'audio') {
          if (newGuest.audioTrack) {
            newGuest.audioTrack?.play();
            setOtherUserAudioFailed(false);
            setOtherUserTrackState((ps) => ({ ...ps, audio: true }));
          } else {
            setTimeout(() => {
              const remoteAudioTrack = newGuest.audioTrack;
              if (remoteAudioTrack) {
                remoteAudioTrack.play();
                setOtherUserTrackState((ps) => ({ ...ps, audio: true }));
              } else {
                setShowOtherUserAudioFailed(true);
                setOtherUserAudioFailed(true);
              }
            }, 1000); // Retry after 1 second
          }
        }
      });

      client.on('user-unpublished', (userr, mediaType) => {
        if (mediaType === 'audio') {
          if (userr.audioTrack) userr.audioTrack.stop();
          setOtherUserTrackState((ps) => ({ ...ps, audio: false }));
        }
        if (mediaType === 'video') {
          setOtherUserTrackState((ps) => ({ ...ps, video: !ps.video }));
        }
      });

      client.on('user-left', async () => {
        setOtherUserTracks(null);
        setOtherUserInCall(false);
        setOtherUserAudioFailed(false);

        if (role.current) {
          setUserLeftCall(getOtherRole(role.current));
        }

        if (interviewerIsRecording.current) {
          setForceStopRecording(true);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await stopRecordingExecuted(true);
          setForceStopRecording(false);
          interviewerIsRecording.current = false;
        }
      });

      client.on('token-privilege-will-expire', async () => {
        const response = await fetchToken();
        await client.renewToken(response.token);
      });

      // When token-privilege-did-expire occurs, fetches a new token from the server and call join to rejoin the channel.
      client.on('token-privilege-did-expire', async () => {
        const response = await fetchToken();
        channelName.current = response.channelName;

        await client.join(
          agoraOptions.appId!,
          response.channelName,
          response.token,
          +response.uid,
        );
      });

      try {
        const response = await fetchToken();
        channelName.current = response.channelName;
        setToken(response.token);

        await client.join(
          agoraOptions.appId!,
          response.channelName,
          response.token,
          +response.uid,
        );
      } catch (error) {
        notifyEndCall();
      }

      if (myTracks?.[1]) {
        // If audio and video are both available, publish both.
        await client.publish([myTracks[0], myTracks[1]]);
      } else {
        // If only audio is available, publish only audio.
        await client.publish(myTracks![0]);
      }
      setPublished(true);
    };

    if (client && myTracks) {
      try {
        publishClient();
      } catch (error) {
        notifyEndCall();
      }
    }
    return () => {
      client.removeAllListeners();
    };
  }, [client, myTracks]);

  const handlePossibleDeleteClip = () => {
    setDisplayClipRecordedWindow(false);
    setShowClipRecordedWindow(false);
    setShowDeleteConfirmationWindow(true);
  };

  const handleDeleteAllRecordedClips = async () => {
    try {
      await AlbumsController.deleteClip(poolContainer.current.clipMain!.id);
    } catch (err: any) {
      setShowError(true);
      return;
    }

    cleanPoolUpdateVideoPartNumber();

    setShowDeleteConfirmationWindow(false);
  };

  const startRecording = async () => {
    const requestData: StartRecordingRequestType = {
      token,
    };

    const response = await AgoraController.startRecording(
      videoCallToken,
      requestData,
    );

    sid.current = response.sid;
    resourceId.current = response.resourceId;
  };

  const stopRecording = async (clipName: string, clipId?: number) => {
    const requestData: StopRecordingRequestType = {
      sid: sid.current,
      resourceId: resourceId.current,
      clipId,
      clipName,
    };

    const stopResponse = await AgoraController.stopRecording(
      videoCallToken,
      requestData,
    );
    return stopResponse;
  };

  const deleteSelectedQuestionFromUnansweredList = (
    alb: AlbumType,
    qSelected: ClipType,
  ) => {
    const unansweredShallowCopyUpdated = alb.unansweredQuestions!.filter(
      (question: ClipType) => question.id !== qSelected.id,
    );
    return { ...alb, unansweredQuestions: unansweredShallowCopyUpdated };
  };

  const handleQuestionsSelected = (question: ClipType) => {
    setQuestionSelected(question);
  };

  const selectNewQuestion = (alb: AlbumType, qSelected: ClipType) => {
    // Custom question selected, nothing happens.
    if (qSelected.id === -1) {
      return;
    }

    const index = alb!.unansweredQuestions!.findIndex(
      (ques: ClipType) => ques.id === qSelected.id,
    );

    if (alb!.unansweredQuestions![index + 1]) {
      setQuestionSelected(alb!.unansweredQuestions![index + 1]);
      return;
    }

    // There are more unanswered questions, select the first one.
    if (alb!.unansweredQuestions!.length > 2) {
      setQuestionSelected(alb!.unansweredQuestions![1]);
    } else {
      setQuestionSelected(alb!.unansweredQuestions![0]);
    }
  };

  // ! Never used because frontend handle only the main clip. "clipsExtras" will be always empty. Backend will be in charge of splitting the video.
  const insertClipRecordedInPoolClipsExtras = (clip: ClipType) => {
    poolContainer.current.clipsExtras.push(clip);
  };

  const insertClipRecordedInPoolClipMain = (clip: ClipType) => {
    poolContainer.current.clipMain = clip;
  };

  const noClipMainInPool = () => !poolContainer.current.clipMain;

  const setBackUpQuestionSelected = () => {
    const index = album!.unansweredQuestions!.findIndex(
      (clip: ClipType) => clip.id === questionSelected.id,
    );

    backupQuestionSelected.current = {
      index,
      backUpQuestion: questionSelected,
    };
  };

  const addAllPoolClipInNewAnsweredList = (
    newAnswered: ClipType[],
    clipMain: ClipType,
    clipsExtras: ClipType[],
  ) => {
    const newAnsweredListCopy = cloneDeep(newAnswered);
    const newAnsweredUpdated = newAnsweredListCopy
      .concat(clipMain)
      .concat(clipsExtras);

    setNewAnsweredQuestions(newAnsweredUpdated);
  };

  //  ! Never used because backend will handle the new clip name.
  const setNewShorterClipName = (
    originalClipName: string,
    actualClipName: string,
  ) => {
    const charactersExceededCount = actualClipName.length - questionsMaxLength;

    const newQuestionName = originalClipName.slice(
      0,
      -charactersExceededCount - 3,
    );

    return `${newQuestionName}..? (${videoPartNumber.current})`;
  };

  const setCustomAsBackUpQuestion = () => {
    backupQuestionSelected.current = {
      index: 0,
      backUpQuestion: album!.unansweredQuestions![0],
    };
  };

  const stopRecordingExecuted = async (manualStop?: boolean) => {
    let clipId;
    let clipName;

    if (noClipMainInPool()) {
      clipName = questionSelected.name;
      clipId = questionSelected.id === -1 ? undefined : questionSelected.id;

      if (questionSelected.id !== -1) {
        setBackUpQuestionSelected();
      } else {
        setCustomAsBackUpQuestion();
      }
      // ! Else block never executed because extra clips will be handle by backend.
    } else {
      clipName = `${questionSelected.name} (${videoPartNumber.current})`;
      clipId = undefined;

      if (clipName.length > questionsMaxLength) {
        clipName = setNewShorterClipName(questionSelected.name, clipName);
      }

      videoPartNumber.current += 1;
    }

    const clipRecordedResponse = await stopRecording(clipName, clipId);

    setAllClipsCount((prev) => prev + 1);

    if (noClipMainInPool()) {
      insertClipRecordedInPoolClipMain(clipRecordedResponse.clip);
    } else {
      insertClipRecordedInPoolClipsExtras(clipRecordedResponse.clip);
    }

    if (manualStop) {
      const { clipMain, clipsExtras } = poolContainer.current;

      addAllPoolClipInNewAnsweredList(
        newAnsweredQuestions,
        clipMain!,
        clipsExtras,
      );

      setShowClipRecordedWindow(true);
      setDisplayClipRecordedWindow(true);

      if (questionSelected.id !== -1) {
        selectNewQuestion(album!, questionSelected);

        const albumUpdated = deleteSelectedQuestionFromUnansweredList(
          album!,
          questionSelected,
        );
        notifyAlbumChange(albumUpdated);
      }
    }
  };

  const confirmClipsRecordedAndCleanPool = () => {
    setDisplayClipRecordedWindow(false);
    setShowClipRecordedWindow(false);
    cleanPoolUpdateVideoPartNumber();
  };

  const handleRecordAction = async (
    isRecording: boolean,
    manualStop?: boolean,
  ) => {
    interviewerIsRecording.current = isRecording;
    if (isRecording) {
      if (online) {
        sendRecordingStarted();
      }
      await startRecording();
      /* Owner can start a new recording even if the clip's recorded window is been showed. If that's the case
      all clips are confirmed, and the clip's pool must be cleaned. */
      if (showClipRecordedWindow) {
        confirmClipsRecordedAndCleanPool();
      }
    } else {
      await sleep(1000);
      setForceStopRecording(true);
      await stopRecordingExecuted(manualStop);
      setForceStopRecording(false);

      if (online) {
        sendRecordingEnded();
      }
    }
  };

  useEffect(() => {
    const checkVideocallTime = async () => {
      if (sCallTimeLeft <= 0) {
        if (interviewerIsRecording.current) {
          stoppingRecording.current = true;
          await handleRecordAction(false);
          stoppingRecording.current = false;
          leaveChannel(true);
        } else if (!stoppingRecording.current) {
          leaveChannel(true);
        }
      }
      if (sCallTimeLeft < sAnnualWarningTimeVideoCall) {
        setShowWarningReachingTimeLimit(true);
      } else {
        setShowWarningReachingTimeLimit(false);
      }
    };
    checkVideocallTime();
  }, [sCallTimeLeft]);

  const showClipRecordedWindowComponent = () => (
    <ClipRecordedWindow
      className={classnames(
        styles.clipWindow,
        displayClipRecordedWindow
          ? styles.openAnimation
          : styles.closeAnimation,
      )}
      notifyDeleteClip={() => handlePossibleDeleteClip()}
      notifyClose={() => hideFast()}
    />
  );

  const showDeleteConfirmationWindowComponent = () => (
    <DeleteConfirmationWindow
      className={styles.clipWindow}
      notifyConfirmDeleteClip={() => handleDeleteAllRecordedClips()}
      notifyClose={() => setShowDeleteConfirmationWindow(false)}
    />
  );

  const showEndCallWarningComponent = () => (
    <div className={styles.endCallWarning}>
      <div className={classnames('text__body__regular__medium__textNeutral00')}>
        Are you sure you want to end this call?
      </div>
      <div className={styles.endCallButtons}>
        <Button
          buttonStyle={ButtonStyle.WhiteGhost}
          onClick={() => {
            setEndCallWarning(false);
          }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.RedFilled}
          onClick={() => {
            leaveChannel();
          }}
        >
          End call
        </Button>
      </div>
    </div>
  );

  const endCallButton = () => (
    <div className={styles.endCallButton}>
      <Button
        buttonSize={ButtonSize.Large}
        buttonStyle={ButtonStyle.RedFilled}
        onClick={() => {
          setEndCallWarning(true);
        }}
        className={styles.button}
      >
        End call
      </Button>
    </div>
  );

  const updateDataAfterUpgradingAlbum = async (
    newTier: SubscriptionSchemeId,
  ) => {
    const updatedAlbum = { ...album!, subscriptionType: newTier };

    const permissionsObject = getPermissionsByAlbum(subSchemes, updatedAlbum);

    calculateCallTimeLeft(updatedAlbum.videoCallProps!);

    setAlbumPermissions(permissionsObject);
    notifyAlbumChange(updatedAlbum);
  };

  const handleModifyAlbumSubscription = async (
    newTier: SubscriptionSchemeId,
  ) => {
    try {
      await AlbumsController.modifyAlbumSubscription(album.id, newTier);
      return album!;
    } catch (err: any) {
      return null;
    }
  };

  const handleSuccessPayment = async (newTier: SubscriptionSchemeId) => {
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Upgrade Album',
        message: 'Upgrade album successful.',
        type: NotificationType.Success,
      }),
    });

    setStartPaymentProcess(false);
    updateDataAfterUpgradingAlbum(newTier);
  };

  const handleError = () => {
    setStartPaymentProcess(false);

    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Modify Album',
        message: 'Subscription failed.',
        type: NotificationType.Error,
      }),
    });
  };

  const showAllPaymentProcessComponent = () => (
    <AllPaymentProcess
      isInsideVideoCall
      albumReceived={album}
      paymentType={PaymentType.ModifyAlbumSubscription}
      successPaymentTextBody="Your subscription has been successfully modified."
      failedPaymentTextBody="Your subscription could not be modified. Please review your payment method and try again."
      notifyProcessConfirmation={(newTier?: SubscriptionSchemeId) =>
        handleModifyAlbumSubscription(newTier!)
      }
      cancelPaymentProcess={() => setStartPaymentProcess(false)}
      successPaymentProcess={(newTier: SubscriptionSchemeId) =>
        handleSuccessPayment(newTier!)
      }
      notifyError={handleError}
      failedPaymentProcess={() => {
        setStartPaymentProcess(false);
      }}
    />
  );

  const showUpgradeButton = () => (
    <Button
      buttonSize={ButtonSize.Large}
      buttonStyle={ButtonStyle.PrimaryStroke}
      onClick={() => setStartPaymentProcess(true)}
      className={styles.button}
    >
      Upgrade subscription
    </Button>
  );

  const getNoVideoQuestions = (questionsList: ClipType[]) =>
    questionsList.filter(
      (q: QuestionType) => q.recordingStatus === RecordingStatus.noVideo,
    );

  const showOwnerMenu = () => (
    <div className={styles.ownerMenu}>
      <OwnerMenu
        unanswered={getNoVideoQuestions(album?.unansweredQuestions || [])}
        answered={album?.clips!}
        newAnsweredQuestions={newAnsweredQuestions}
        questionSelected={questionSelected}
        clipsCountRecorded={clipsCountRecorded}
        maxNumberRecordingChunks={maxNumberRecordingChunks.current}
        clipLimitReached={allClipsCount === album.maxClips}
        albumQuestionsPermissionLimit={album.maxClips}
        forceStopRecording={forceStopRecording}
        noAbleToStartRecording={
          showDeleteConfirmationWindow || allClipsCount === album.maxClips
        }
        otherUserInCall={otherUserInCall}
        otherUserTracks={otherUserTracks}
        otherUserTrackState={otherUserTrackState}
        notifyQuestionsSelected={(question: ClipType) =>
          handleQuestionsSelected(question)
        }
        notifyRecordAction={handleRecordAction}
        notifyErrorRecording={(message: string) =>
          handleErrorRecording(message)
        }
        notifyIncrementClipCountRecorded={() => {
          setClipsCountRecorded((prevState) => prevState + 1);
        }}
        notifyResetClipCountRecorded={() => {
          setClipsCountRecorded(1);
        }}
        otherUserAudioFailed={otherUserAudioFailed}
      />
    </div>
  );

  const getButtonText = () => {
    if (showWarningReachingClipLimit && showWarningReachingTimeLimit) {
      return 'Get more time and clips';
    }
    if (showWarningReachingClipLimit) {
      return 'Get more clips';
    }
    return 'Get more time';
  };

  const showButtonToBuyTimeOrClips = () => (
    <div className={classnames(styles.upgradeButton)}>
      <Button
        buttonSize={ButtonSize.Large}
        buttonStyle={ButtonStyle.PrimaryStroke}
        onClick={() => setShowModalBuyClipsOrTime(true)}
        className={styles.button}
      >
        {getButtonText()}
      </Button>
    </div>
  );

  const showErrorNotification = () => (
    <Notification
      message={errorMessage}
      handleClose={() => setShowError(false)}
    />
  );

  const showMaxClipsAllowReachedComponent = () => (
    <div className={styles.topWarning}>
      <WarningReachingClipsLimit
        maxQuestionsPerAlbum={album.maxClips}
        allClipsCount={allClipsCount}
      />
    </div>
  );

  const showTimeLimitComponent = () => (
    <div className={styles.topWarning}>
      <WarningReachingTimeLimit
        stimeLeft={sCallTimeLeft > 0 ? sCallTimeLeft : 0}
      />
    </div>
  );

  const showUserLeftCallWarningComponent = (roleLeft: VideoCallRole) => (
    <WarningUserLeftCall
      notifyClose={() => setUserLeftCall(undefined)}
      role={roleLeft}
    />
  );

  const showModalClipsTime = () => (
    <ModalBuyTimeSubscription
      onClose={() => setShowModalBuyClipsOrTime(false)}
      onBuyTime={() => {
        setShowModalBuyClipsOrTime(false);
        setShowModalBuyExtraTime(true);
      }}
      onUpgradeSubscription={() => {
        setShowModalBuyClipsOrTime(false);
        setStartPaymentProcess(true);
      }}
      onBuyClips={() => {
        setShowModalBuyClipsOrTime(false);
        setShowModalBuyClips(true);
      }}
      album={album}
    />
  );
  const modalBuyClips = () => (
    <BuyClipsProcess
      album={album}
      onFinishProcess={() => setShowModalBuyClips(false)}
    />
  );

  const modalBuyExtraTime = () => (
    <BuyExtraCallTimeProcess
      onFinishProcess={() => setShowModalBuyClips(false)}
      album={album}
      refetchAlbum={refetchAlbum}
    />
  );

  const modalConnectionFailed = () => (
    <ModalConnectionFailed
      handleLeaveCall={notifyEndCall}
      handleRefreshPage={() => window.location.reload()}
    />
  );

  const handleSwitchRole = (newRole: VideoCallRole) => {
    if (newRole !== role.current) {
      role.current = newRole;
      waitForRequest.current = true;
      setChangeRoleLoading(true);
      updateRole(newRole);
      if (newRole === VideoCallRole.INTERVIEWER) {
        refetchAlbum();
      }
      setTimeout(() => {
        setChangeRoleLoading(false);
      }, 2000);
    }
  };

  const showSwitchRoleButton = (currentRole: VideoCallRole) => {
    const selectDisabled =
      !user.permissions?.permissions.changeVideocallRole ||
      !!callInformation?.videocallRespondentIsGuest;
    return (
      <>
        <span className="text__body__regular__medium__textNeutral00">
          Role:
        </span>
        <Select
          options={Object.values(VideoCallRole).map((value: string) => ({
            id: capitalizeFirstLetter(value),
            value: capitalizeFirstLetter(value),
          }))}
          optionSelected={{ id: currentRole, value: currentRole }}
          onClickOption={(option) => {
            handleSwitchRole(
              (option.id as string).toLowerCase() as VideoCallRole,
            );
          }}
          id="role"
          value={capitalizeFirstLetter(currentRole)}
          className={classnames(styles.selectRole, {
            [styles.selectDisabled]: selectDisabled,
          })}
          disabled={selectDisabled}
          tooltip={!user.permissions?.permissions.changeVideocallRole}
          tooltipContent="Available after sign up"
          tooltipId="switch-role-tooltip"
          tooltipPosition="bottom"
        />
      </>
    );
  };

  const showChangingRoleModal = () => <ModalRoleChanging />;

  const showVideocallWarnings = () => (
    <div className={styles.videocallWarnings}>
      {showWarningReachingClipLimit && showMaxClipsAllowReachedComponent()}
      {showWarningReachingTimeLimit && showTimeLimitComponent()}
    </div>
  );

  const showModalSignUp = () => (
    <ModalWarning
      title="Sign up to save your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            You’ve run out of free trial clips! Please complete the sign up
            process to be able to save your clips and access them later,
            download or share them, as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  const modalOtherUSerAudioFailed = () => (
    <ModalOtherUserAudioFailed
      notifyOnClose={() => setShowOtherUserAudioFailed(false)}
    />
  );

  return (
    <>
      {startPaymentProcess && showAllPaymentProcessComponent()}
      {showModalBuyClipsOrTime && showModalClipsTime()}
      {showModalBuyClips && modalBuyClips()}
      {showModalBuyTime && modalBuyExtraTime()}
      {showModalConnectionFailed && modalConnectionFailed()}
      {changeRoleLoading && showChangingRoleModal()}
      {showOtherUserAudioFailed && modalOtherUSerAudioFailed()}
      {signUpModal && showModalSignUp()}

      <div className={styles.VideoCallContainer}>
        <div className={styles.overlayContainer}>
          <div className={styles.topSection}>
            <div className={styles.topLeftSection}>
              <div className={styles.basicCallButtons}>
                {endCallButton()}
                {/* TODO: For now, we use <CopyLink /> component. Previously we were using <SendLinkToContributor /> */}
                {published && !otherUserInCall && (
                  <CopyLink
                    buttonSize={ButtonSize.Large}
                    buttonStyle={ButtonStyle.PrimaryStroke}
                    className={classnames(styles.linkOption, styles.button)}
                    path={`${apiBaseURL}/albums/${album.id}/join/${album
                      .videoCallProps?.videoCallToken!}`}
                  >
                    <LinkIcon className={styles.linkIcon} />
                    <div className={styles.linkText}> Copy link to album </div>
                  </CopyLink>
                )}
                <div className={styles.toggleButtons}>
                  {published && (
                    <SensorToggleButton
                      sensorType="microphone"
                      buttonSize={ButtonSize.Large}
                      disabled={recordingInProgress && !isOwner(user, album!)}
                      iconClass={styles.iconToggle}
                    />
                  )}
                  {published && (
                    <SensorToggleButton
                      sensorType="camera"
                      buttonSize={ButtonSize.Large}
                      disabled={recordingInProgress && !isOwner(user, album!)}
                      iconClass={styles.iconToggle}
                    />
                  )}
                </div>
                {published &&
                  isFreeTrial(album) &&
                  isOwner(user, album!) &&
                  canUpgradeAlbum(album, subSchemes) &&
                  userPermissionUpgrade(user) &&
                  showUpgradeButton()}
                {published &&
                  role.current === VideoCallRole.INTERVIEWER &&
                  showVideocallWarnings()}
                {role.current === VideoCallRole.INTERVIEWER &&
                  !isFreeTrial(album) &&
                  (showWarningReachingClipLimit ||
                    showWarningReachingTimeLimit) &&
                  showButtonToBuyTimeOrClips()}
              </div>
              {endCallWarning && showEndCallWarningComponent()}
            </div>
            {role.current === VideoCallRole.RESPONDENT &&
              recordingInProgress && (
                <div className={styles.recordingInProgress}>
                  <VideoIcon className={styles.infoIcon} />
                  <p className="text__body__regular__small__textNeutral00">
                    Recording in progress...
                  </p>
                </div>
              )}
            <div className={styles.topRightSection}>
              {published &&
                callInformation &&
                role.current &&
                showSwitchRoleButton(role.current)}
            </div>
          </div>
          {showError && showErrorNotification()}

          <div className={styles.bottomSection}>
            {published &&
              userLeftCall &&
              callInformation &&
              showUserLeftCallWarningComponent(userLeftCall)}
            <div className={styles.clipWindows}>
              {showDeleteConfirmationWindow &&
                showDeleteConfirmationWindowComponent()}
              {showClipRecordedWindow && showClipRecordedWindowComponent()}
            </div>
            {showRoleSwitched && callInformation && (
              <WarningRoleUpdated
                newRole={getUserRole(callInformation, user.id)}
                notifyClose={() => setShowRoleSwitched(false)}
              />
            )}
            {album &&
              callInformation &&
              getUserRole(callInformation, user.id) ===
                VideoCallRole.INTERVIEWER &&
              showOwnerMenu()}
          </div>
        </div>

        <div className={styles.video}>
          {published && (
            <Video
              user={user}
              album={album!}
              myTracks={myTracks}
              isRecording={interviewerIsRecording.current}
              otherUserTracks={otherUserTracks}
              otherUserTrackState={otherUserTrackState}
              otherUserInCall={otherUserInCall}
              userRole={role.current}
              otherUserName={
                role.current === VideoCallRole.INTERVIEWER
                  ? callInformation?.videocallRespondentName
                  : callInformation?.videocallInterviewerName
              }
              otherUserIsGuest={
                role.current === VideoCallRole.INTERVIEWER &&
                !!callInformation?.videocallRespondentIsGuest
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export { VideoCall };
