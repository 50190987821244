import { LinkIcon } from 'assets/icons';
import { CopyLink } from 'common/copy-link';
import { ShareByEmail } from 'common/share-by-email';
import { RouteName } from 'routes';
import { apiBaseURL } from 'config/constants';
import { getRouteFor } from 'routes/route-helpers';
import { classnames } from 'helpers/utils';

import styles from './share-clip.module.scss';

type ShareClipProps = {
  clip: ClipType;
  album: AlbumType;
  showModalWarning?: boolean;
};

const ShareClip: React.FC<ShareClipProps> = ({
  clip,
  album,
  showModalWarning = false,
}) => (
  <div className={styles.shareSection}>
    <span
      className={classnames(
        styles.shareClipDescription,
        'text__body__regular__medium__textNeutral40',
      )}
    >
      {' '}
      Share clip:{' '}
    </span>
    <CopyLink
      className={styles.optionsContainer}
      path={`${apiBaseURL}${getRouteFor(RouteName.ClipDetailGuest, {
        albumId: album.id,
        clipId: clip.id,
      })}`}
      showModalWarning={showModalWarning}
    >
      <LinkIcon className={styles.linkIcon} />
      <span className={styles.linkText}> Copy link </span>
    </CopyLink>
    <ShareByEmail className={styles.optionsContainer} clip={clip} />
  </div>
);

export { ShareClip };
