/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, HTMLProps, useEffect, useState } from 'react';
import { classnames } from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './toggle.module.scss';

type ToggleProperties = {
  id?: string;
  containerClass?: string;
  onChangeFn: (e: ChangeEvent<HTMLInputElement>) => void;
  tooltipText?: string;
  showTooltip?: boolean;
  buttonContainerClass?: string;
};

const Toggle = ({
  id = 'switch',
  onChangeFn,
  containerClass = '',
  tooltipText = '',
  showTooltip = false,
  buttonContainerClass = '',
  ...props
}: ToggleProperties & HTMLProps<HTMLInputElement>) => {
  const [showMsg, setShowMsg] = useState(false);
  const [display, setDisplay] = useState(false);

  const handleClickDisabled = () => {
    if (props.disabled) {
      setDisplay(true);
      setShowMsg(true);
    }
  };

  useEffect(() => {
    if (showTooltip) {
      setShowMsg(true);
    }
  }, [showTooltip]);

  const hidden = () => {
    setDisplay(false);
    setTimeout(() => setShowMsg(false), 900);
  };

  useEffect(() => {
    if (showMsg) {
      setTimeout(hidden, 3000);
    }
  }, [showMsg]);

  const tooltipClass = classnames(
    'text__body__regular__small__textNeutral30',
    styles.tooltip,
    display ? styles.openAnimation : styles.closeAnimation,
  );

  return (
    <Button
      buttonStyle={ButtonStyle.PrimaryGhost}
      buttonSize={ButtonSize.Tiny}
      className={classnames(styles.container, buttonContainerClass)}
      onClick={handleClickDisabled}
    >
      {showMsg && <span className={tooltipClass}>{tooltipText}</span>}
      <input
        id={id}
        type="checkbox"
        className={styles.inputCh}
        onChange={onChangeFn}
        {...props}
      />
      <label
        className={classnames(styles.labelCh, containerClass)}
        htmlFor={id}
      />
    </Button>
  );
};

export { Toggle };
