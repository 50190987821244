import { CrossIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import { useContext, useEffect } from 'react';
import { AppContext, appActions } from 'context';
import styles from './image-full-screen.module.scss';

type ImageFullScreenType = {
  isQrCode?: boolean;
};

const ImageFullScreen: React.FC<ImageFullScreenType> = ({ isQrCode }) => {
  const { state, dispatch } = useContext(AppContext);
  const clearEnlargedImage = () => {
    dispatch({
      type: appActions.SET_IMAGE_TO_ENLARGE,
      imageToEnlarge: undefined,
    });
    document.body.style.overflow = `auto`; // Enable scrolling
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    document.body.style.overflow = 'hidden'; // Prevent scrolling
  }, []);

  return (
    <>
      <div className={classnames(styles.container)}>
        <button
          type="button"
          onClick={clearEnlargedImage}
          className={styles.closeButton}
        >
          <CrossIcon />
        </button>
      </div>
      <div className={isQrCode ? styles.overlayQrCode : styles.overlay}>
        <img
          src={state.imageToEnlarge}
          alt="Img Full Screen"
          className={isQrCode ? styles.imageQRcode : styles.imageFullScreen}
        />
      </div>
    </>
  );
};

export { ImageFullScreen };
