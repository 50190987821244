import React, { HTMLProps, ReactNode } from 'react';
import { classnames } from 'helpers/utils';
import { PlacesType, Tooltip } from 'react-tooltip';
import styles from './button.module.scss';

enum ButtonType {
  Submit = 'submit',
  Button = 'button',
}

enum ButtonStyle {
  PrimaryFilled = 'PrimaryFilled',
  PrimaryGhost = 'PrimaryGhost',
  PrimaryRoundedFilled = 'PrimaryRoundedFilled',
  PrimaryRoundedStroke = 'PrimaryRoundedStroke',
  PrimaryGhostLink = 'PrimaryGhostLink',
  PrimaryStroke = 'PrimaryStroke',
  SecondaryFilled = 'SecondaryFilled',
  SecondaryRoundedFilled = 'SecondaryRoundedFilled',
  SecondaryStroke = 'SecondaryStroke',
  SecondaryRoundedStroke = 'SecondaryRoundedStroke',
  SecondaryGhostLink = 'SecondaryGhostLink',
  SecondaryGhost = 'SecondaryGhost',
  GreyGhost = 'GreyGhost',
  RedFilled = 'RedFilled',
  RedGhost = 'RedGhost',
  WhiteFilled = 'WhiteFilled',
  WhiteGhost = 'WhiteGhost',
  RedStroke = 'RedStroke',
}

enum ButtonSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  Tiny = 'Tiny',
}

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  dataCy?: string;
  tooltip?: boolean;
  tooltipContent?: ReactNode;
  tooltipPosition?: PlacesType;
  tooltipId?: string;
}

function handleClassStyle(
  buttonStyle: ButtonStyle,
  buttonSize: ButtonSize,
  className: string,
) {
  const classes = [styles.buttonContainer, className];

  switch (buttonStyle) {
    case ButtonStyle.PrimaryFilled:
      classes.push(styles.primaryFilled);
      break;
    case ButtonStyle.SecondaryFilled:
      classes.push(styles.secondaryFilled);
      break;
    case ButtonStyle.RedFilled:
      classes.push(styles.redFilled);
      break;
    case ButtonStyle.WhiteFilled:
      classes.push(styles.whiteFilled);
      break;
    case ButtonStyle.PrimaryGhost:
      classes.push(styles.primaryGhost);
      break;
    case ButtonStyle.SecondaryGhost:
      classes.push(styles.secondaryGhost);
      break;
    case ButtonStyle.GreyGhost:
      classes.push(styles.greyGhost);
      break;
    case ButtonStyle.RedGhost:
      classes.push(styles.redGhost);
      break;
    case ButtonStyle.WhiteGhost:
      classes.push(styles.whiteGhost);
      break;
    case ButtonStyle.PrimaryRoundedFilled:
      classes.push(styles.primaryRoundedFilled);
      break;
    case ButtonStyle.SecondaryRoundedFilled:
      classes.push(styles.secondaryRoundedFilled);
      break;
    case ButtonStyle.PrimaryStroke:
      classes.push(styles.primaryStroke);
      break;
    case ButtonStyle.SecondaryStroke:
      classes.push(styles.secondaryStroke);
      break;
    case ButtonStyle.PrimaryRoundedStroke:
      classes.push(styles.primaryRoundedStroke);
      break;
    case ButtonStyle.SecondaryRoundedStroke:
      classes.push(styles.secondaryRoundedStroke);
      break;
    case ButtonStyle.PrimaryGhostLink:
      classes.push(styles.primaryGhostLink);
      break;
    case ButtonStyle.SecondaryGhostLink:
      classes.push(styles.secondaryGhostLink);
      break;
    case ButtonStyle.RedStroke:
      classes.push(styles.redStroke);
      break;
    default:
      break;
  }

  switch (buttonSize) {
    case ButtonSize.Large:
      classes.push(styles.large);
      break;
    case ButtonSize.Medium:
      classes.push(styles.medium);
      break;
    case ButtonSize.Small:
      classes.push(styles.small);
      break;
    case ButtonSize.Tiny:
      classes.push(styles.tiny);
      break;
    default:
      break;
  }

  return classnames(...classes);
}

const Button = ({
  children,
  className = '',
  buttonType = ButtonType.Button,
  buttonStyle = ButtonStyle.PrimaryFilled,
  buttonSize = ButtonSize.Medium,
  dataCy,
  tooltip,
  tooltipContent,
  tooltipPosition,
  tooltipId,
  ...props
}: ButtonProps) => (
  <button
    className={handleClassStyle(buttonStyle, buttonSize, className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    // eslint-disable-next-line react/button-has-type
    type={buttonType}
    data-cy={dataCy}
    data-tooltip-id={tooltipId}
  >
    {children}
    <Tooltip
      id={tooltipId}
      place={tooltipPosition}
      className={styles.tooltip}
      hidden={!tooltip}
    >
      {tooltipContent}
    </Tooltip>
  </button>
);

export { Button, ButtonStyle, ButtonType, ButtonSize };
