/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useContext, useEffect } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { useClickOutside } from 'hooks/click-outside';
import { Menu, PositionType } from 'common/menu';
import {
  classnames,
  copyToClipboard,
  hasAlbumAccess,
  isAnyLoggedUser,
  isOwnerOrClipOwner,
  isReadOnly,
  isRestrictedAlbum,
  isTemporaryUser,
  showTextEllipsis,
} from 'helpers/utils';
import { dayjs } from 'helpers/dayjs';
import thumbnailDefault from 'assets/images/thumbnail-default.png';
import { apiBaseURL } from 'config/constants';
import { AppContext, appActions } from 'context/app-context';
import { RouteName } from 'routes';
import { ButtonStyle } from 'common/button';
import { getRouteFor, goToPage } from 'routes/route-helpers';
import { ModalWarning } from 'common/modal-warning';
import { DragIcon, QrTinyIcon } from 'assets/icons';
import { isEmpty } from 'lodash';
import { ImageAudio } from 'common/image-audio';
import {
  Breakpoints,
  ClipActions,
  NotificationType,
  RecordingStatus,
} from 'common/enums';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { NotificationObject } from 'models/notificationObject';
import { createUrlToDownloadFile } from 'helpers/file-utils';
import { useMediaQuery } from 'hooks/use-media-query';
import styles from './grouped-clips.module.scss';

type GroupedClipsProps = {
  album: AlbumType;
  clipSelected: ClipType;
  publicView?: boolean;
  notifyClipAction: (
    action: ClipActions,
    clip: ClipType,
    extraData?: ClipExtraDataType,
  ) => void;
  notifyStopClip?: () => void;
  notifyChangeClipOrder?: (clips: ClipType[]) => void;
};

type DeleteClipModalProps = {
  show: boolean;
  clip?: ClipType;
};

const GroupedClips: React.FC<GroupedClipsProps> = ({
  clipSelected,
  publicView = false,
  album,
  notifyClipAction,
  notifyStopClip,
  notifyChangeClipOrder,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const getRecordedClips = (clipsList: ClipType[]) =>
    clipsList.filter(
      (clip: ClipType) => clip.recordingStatus === RecordingStatus.recorded,
    );

  const [clipsRecorded, setClipRecorded] = useState<ClipType[]>(
    getRecordedClips(album.clips || []),
  );
  const [showClipOptionsMenu, setShowClipOptionsMenu] = useState(false);
  const [deleteClipModal, setDeleteClipModal] = useState<DeleteClipModalProps>({
    show: false,
  });
  const [idClipOptionsSelected, setIdClipOptionsSelected] = useState(-1);

  const [clipHoveredId, setClipHoveredId] = useState<number | null>(null);

  const [showModalSignUp, setShowModalSignUp] = useState(false);

  const { user } = state.data;

  const containerClipOptionsRef = useRef(null);

  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  useClickOutside(containerClipOptionsRef, () => {
    setIdClipOptionsSelected(-1);
    setShowClipOptionsMenu(false);
  });

  const showDeleteClipButton = (clip: ClipType) => (
    <button
      type="button"
      onClick={() => {
        notifyStopClip!();
        setDeleteClipModal({ show: true, clip });
      }}
      className="text__body__regular__medium__textNeutral30"
    >
      Delete clip
    </button>
  );

  const showCopyLinkButton = (clip: ClipType) => (
    <button
      type="button"
      onClick={
        isTemporaryUser(user)
          ? () => setShowModalSignUp(true)
          : () => {
              copyToClipboard(
                `${apiBaseURL}${getRouteFor(RouteName.ClipDetailGuest, {
                  albumId: album.id,
                  clipId: clip.id,
                })}`,
              );
            }
      }
      className="text__body__regular__medium__textNeutral30"
    >
      Copy link
    </button>
  );
  const refetchAlbum = async () => {
    try {
      const response = await AlbumsController.getAlbum(album.id);
      if (!response || !response.clips) {
        return;
      }
      setClipRecorded(getRecordedClips(response.clips || []));
    } catch {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again later.',
          type: NotificationType.Error,
        }),
      });
    }
  };

  useEffect(() => {
    if (isAnyLoggedUser(user) && hasAlbumAccess(user, album)) {
      refetchAlbum();
    }
  }, [album.clips]);

  const showEditClipButton = (clip: ClipType) => (
    <button
      type="button"
      onClick={() => {
        notifyClipAction(ClipActions.editClip, clip);
      }}
      className="text__body__regular__medium__textNeutral30"
    >
      Edit info
    </button>
  );

  const showDeleteClipModalComponent = () => (
    <ModalWarning
      title="Deleting clip"
      content="Are you sure you want to delete this clip? Deleted clips cannot be recovered."
      successButtonText="Delete clip"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setDeleteClipModal({ show: false })}
      successFn={() => {
        notifyClipAction(ClipActions.delete, deleteClipModal.clip!);
        setShowClipOptionsMenu(false);
        setDeleteClipModal({ show: false });
      }}
    />
  );

  const showClipStatusOption = (clip: ClipType) => (
    <button
      type="button"
      onClick={() => notifyClipAction(ClipActions.togglePrivate, clip)}
      className="text__body__regular__medium__textNeutral30"
    >
      {clip.isPrivate ? 'Make public' : 'Make private'}
    </button>
  );

  const showClipAudioOnlyOption = (clip: ClipType) => (
    <button
      type="button"
      onClick={() => notifyClipAction(ClipActions.toggleAudioOnly, clip)}
      className="text__body__regular__medium__textNeutral30"
    >
      {clip.isAudioOnly ? 'Turn into video' : 'Turn into audio'}
    </button>
  );

  const showClipsOptionsMenu = (clip: ClipType) => {
    const indexClip = clipsRecorded?.findIndex(
      (clipItem) => clipItem.id === clip.id,
    );

    const showTopLeft =
      indexClip &&
      album.clips &&
      album.clips.length > 4 &&
      indexClip > album.clips!.length - 4;
    return (
      <div ref={containerClipOptionsRef}>
        <Menu
          position={
            showTopLeft ? PositionType.TopLeft : PositionType.BottomLeft
          }
          width={styles.clipOptionsMenu}
          alwaysVisible
        >
          {hasAlbumAccess(user, album) && showClipStatusOption(clip)}
          {hasAlbumAccess(user, album) && showClipAudioOnlyOption(clip)}

          {!clip.isPrivate && showCopyLinkButton(clip)}

          {hasAlbumAccess(user, album) && <hr />}

          {hasAlbumAccess(user, album) && showEditClipButton(clip)}
          {isOwnerOrClipOwner(user, album, clip) && showDeleteClipButton(clip)}

          <button
            type="button"
            className={classnames(
              styles.downloadButton,
              'text__body__regular__medium__textNeutral30',
            )}
            onClick={
              isTemporaryUser(user) ? () => setShowModalSignUp(true) : undefined
            }
          >
            {isTemporaryUser(user) ? (
              <div className="text__body__regular__medium__textNeutral30">
                Download clip
              </div>
            ) : (
              <a
                className="text__body__regular__medium__textNeutral30"
                href={createUrlToDownloadFile(clip.videoUrl!, clip.name)}
                download={clip.name}
              >
                Download clip
              </a>
            )}
          </button>
          {clip.customThumbnail && (
            <button
              type="button"
              className={classnames(
                styles.downloadButton,
                'text__body__regular__medium__textNeutral30',
              )}
              onClick={
                isTemporaryUser(user)
                  ? () => setShowModalSignUp(true)
                  : undefined
              }
            >
              {isTemporaryUser(user) ? (
                <div className="text__body__regular__medium__textNeutral30">
                  Download picture
                </div>
              ) : (
                <a
                  className="text__body__regular__medium__textNeutral30"
                  href={createUrlToDownloadFile(
                    clip.customThumbnail,
                    clip.name,
                  )}
                  download={clip.name}
                >
                  Download picture
                </a>
              )}
            </button>
          )}
        </Menu>
      </div>
    );
  };

  const showModalSignUpComponent = () => (
    <ModalWarning
      title="Sign up to share or download your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            Please complete the sign up process to share or download your clips,
            as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  const showThreeDotsOptions = (clip: ClipType) => (
    <div className={styles.clipsDots}>
      <button
        type="button"
        key={clip.id}
        className={classnames(globalStyles.threeDots, styles.dotsColor)}
        onClick={() => {
          setShowClipOptionsMenu(!showClipOptionsMenu);
          setIdClipOptionsSelected(clip.id);
        }}
      >
        {idClipOptionsSelected === clip.id &&
          showClipOptionsMenu &&
          showClipsOptionsMenu(clip)}
      </button>
    </div>
  );

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const clips = Array.from(clipsRecorded);
    const [removed] = clips.splice(source.index, 1);
    clips.splice(destination.index, 0, removed);

    const allClipsList = clips.concat(album.unansweredQuestions || []);

    const requestData: AlbumEditRequestType = {
      album: {
        questions: allClipsList,
      },
    };

    setClipRecorded(clips);

    try {
      const response = await AlbumsController.updateAlbumQuestions(
        album.id,
        requestData,
      );
      if (!response || !response.clips) {
        return;
      }
      const clipsInOrder = getRecordedClips(response.clips);
      if (notifyChangeClipOrder) {
        notifyChangeClipOrder(clipsInOrder);
      }
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Changes saved',
          message: '',
          type: NotificationType.Success,
        }),
      });
      setClipRecorded(clipsInOrder);
    } catch (error) {
      if (album.clips) {
        const clipsBeforeAction = getRecordedClips(album.clips);
        setClipRecorded(clipsBeforeAction);
      }
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again later.',
          type: NotificationType.Error,
        }),
      });
    }
  };

  const showClip = (clip: ClipType, selectedClip: ClipType, index: number) => (
    <Draggable
      key={index}
      draggableId={`${index}`}
      index={index}
      isDragDisabled={
        isReadOnly(album) ||
        !isAnyLoggedUser(user) ||
        !hasAlbumAccess(user, album)
      }
    >
      {(provided, snapshot) => (
        <div
          role="listbox"
          key={clip.id}
          className={classnames(
            styles.clipItem,
            clip.id === selectedClip.id ? styles.clipSelected : '',
          )}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onMouseEnter={() => {
            setClipHoveredId(clip.id);
          }}
          onMouseLeave={() => {
            setClipHoveredId(null);
          }}
          tabIndex={0}

          // it is a div because is the way to make it draggable and clickable
        >
          <div
            onClick={() => {
              notifyClipAction(ClipActions.select, clip, {
                autoPlaying: true,
              });
            }}
            tabIndex={0}
            className={styles.numberAndInfo}
            role="button"
          >
            <div
              className={classnames(styles.numberAndInfo, {
                [styles.clipSelected]: clip.id === selectedClip.id,
              })}
            >
              <span
                className={classnames(
                  styles.numberInList,
                  'text__body__regular__tiny__textNeutral30',
                  {
                    [styles.draggingIcon]: snapshot.isDragging,
                    [styles.clipSelected]: clip.id === selectedClip.id,
                  },
                )}
              >
                {snapshot.isDragging ||
                (hasAlbumAccess(user, album) && clip.id === clipHoveredId) ? (
                  <DragIcon />
                ) : (
                  index + 1
                )}
              </span>

              <div className={styles.clipContent}>
                <div className={styles.picture}>
                  {clip.isAudioOnly ? (
                    <ImageAudio src={clip.customThumbnail} alt="" />
                  ) : (
                    <img
                      src={
                        clip.customThumbnail ||
                        clip.thumbnailUrl ||
                        thumbnailDefault
                      }
                      alt=""
                    />
                  )}
                </div>
                <div className={styles.descriptionAndStatus}>
                  <div
                    className={
                      clip.id === selectedClip.id
                        ? 'text__body__regular__small__textNeutral40'
                        : 'text__body__regular__small__textNeutral30'
                    }
                  >
                    {isMobile ? showTextEllipsis(clip.name, 35) : clip.name}
                  </div>
                  <div className={styles.clipLengthAndStatus}>
                    <div className={styles.lengthAndDate}>
                      <span className="text__body__regular__overline__textNeutral30">
                        {dayjs.duration(clip.length, 'seconds').format('mm:ss')}
                        {'  '}-{'  '}
                        {dayjs(clip.recordedAt)
                          .format('MMMM D, YYYY')
                          .toUpperCase()}
                      </span>
                    </div>

                    <div className={styles.infoClip}>
                      {!isEmpty(clip.qrCodes) && (
                        <QrTinyIcon className={styles.qrIcon} />
                      )}
                      {clip.isPrivate && (
                        <div
                          className={classnames(
                            'text__body__semi__bold__overline__textNeutral30',
                            styles.privateText,
                          )}
                        >
                          {' '}
                          PRIVATE{' '}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!publicView &&
            !isRestrictedAlbum(album) &&
            !isReadOnly(album) &&
            showThreeDotsOptions(clip)}
        </div>
      )}
    </Draggable>
  );

  return (
    <>
      {deleteClipModal.show && showDeleteClipModalComponent()}
      {showModalSignUp && showModalSignUpComponent()}

      <div className={styles.groupDateAndClips}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="clips-list" type="Clips">
            {(provided) => (
              <div
                className={classnames(
                  'text__body__regular__small__textNeutral30',
                )}
                ref={provided.innerRef}
              >
                {clipsRecorded.map((clip, index) =>
                  showClip(clip, clipSelected, index),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export { GroupedClips };
