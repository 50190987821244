import { Modal } from 'common/modal';
import { InfoIcon } from 'assets/icons';
import qrImage from 'assets/images/qrcode.png';
import { classnames, getClipNameOrAlbumTitle } from 'helpers/utils';
import dayjs from 'dayjs';
import { AppLink, RouteName } from 'routes';
import { useContext, useEffect, useState } from 'react';
import { AppContext, appActions } from 'context';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { NotificationType } from 'common/enums';
import { NotificationObject } from 'models/notificationObject';
import styles from './qr-codes-details-modal.module.scss';

type QrCodesDetailsModalProps = {
  qrCodes: QrCode[];
  onClose: () => void;
  album?: AlbumType;
};

const QrCodesDetailsModal: React.FC<QrCodesDetailsModalProps> = ({
  qrCodes,
  onClose,
  album,
}) => {
  const { dispatch, state } = useContext(AppContext);
  const [albumQrCodes, setAlbumQrCodes] = useState<AlbumType>();

  const handleGetAlbum = async (albumId: number) => {
    try {
      const albumInfo = await AlbumsController.getAlbum(albumId);
      setAlbumQrCodes(albumInfo);
    } catch (error) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again',
          type: NotificationType.Error,
        }),
      });
      onClose();
    }
  };

  useEffect(() => {
    if (!album) {
      handleGetAlbum(qrCodes[0].albumId);
    } else {
      setAlbumQrCodes(album);
    }
  }, []);

  const qrCodesListDetails = (codes: QrCode[]) => (
    <div className={styles.qrCodesListModal}>
      <div className="text__body__regular__medium__textNeutral40">
        This album has been linked to the following QR codes:
      </div>
      {codes.map((code) => (
        <div key={code.id} className={styles.qrCodeItem}>
          <button
            onClick={() =>
              dispatch({
                type: appActions.SET_IMAGE_TO_ENLARGE,
                imageToEnlarge: code.image || qrImage,
                isQrCodeImageEnlarged: true,
              })
            }
            type="button"
          >
            <img
              className={styles.qrImage}
              src={code.image || qrImage}
              alt="QR code"
            />
          </button>
          <div>
            <div className="text__body__semi__bold__medium__textNeutral40">
              {albumQrCodes &&
                getClipNameOrAlbumTitle(code.clipId, albumQrCodes)}
            </div>
            <div className="text__body__regular__small__textNeutral30">
              By {code.owner}
            </div>
            <div className="text__body__regular__small__textNeutral30">
              {dayjs(code.linkDate).format('MMMM Do, YYYY')}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.infoContainer}>
        <InfoIcon className={styles.infoIcon} />
        <div>
          <div className={styles.infoText}>
            <span
              className={classnames('text__body__regular__tiny__textNeutral30')}
            >
              Buy QR codes from the{' '}
              <AppLink className={styles.infoLink} routeName={RouteName.Store}>
                <span className="text__body__regular__tiny__primary50">
                  Generational Story Store
                </span>
              </AppLink>
              .
            </span>
          </div>
          <div className={styles.infoText}>
            <span
              className={classnames('text__body__regular__tiny__textNeutral30')}
            >
              Manage QR codes from the Generational Story App.
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      className={classnames({
        [styles.transparentBackground]: !!state.imageToEnlarge,
      })}
      contentClassName={styles.container}
      title="Active QR Code"
      onClose={onClose}
    >
      {qrCodesListDetails(qrCodes)}
    </Modal>
  );
};

export { QrCodesDetailsModal };
