import { ChevronIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { classnames } from 'helpers/utils';
import { ButtonProps } from 'common/button/button';
import styles from './link-button.module.scss';

const LinkButton = ({ className = '', children, ...props }: ButtonProps) => (
  <Button
    className={classnames(styles.linkButton, className)}
    buttonStyle={ButtonStyle.PrimaryGhost}
    buttonSize={ButtonSize.Small}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    {children}
    <ChevronIcon className={styles.chevronIcon} />
  </Button>
);

export { LinkButton };
