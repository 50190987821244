/* eslint-disable react/jsx-props-no-spreading */
import {
  FunctionComponent,
  HTMLProps,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { InputProps } from 'common/input/input';
import { CrossIcon, ChevronIcon } from 'assets/icons';
import { useClickOutside } from 'hooks/click-outside';
import { classnames } from 'helpers/utils';
import { PlacesType, Tooltip } from 'react-tooltip';
import styles from './select.module.scss';

type SelectProps = {
  options: SelectType[];
  optionSelected: SelectType;
  onClickOption: (option: SelectType) => void;
  optionTextClass?: string;
  selectContainerClass?: string;
  containerClass?: string;
  ErrorIconComp?: FunctionComponent;
  title?: string;
  withValidation?: boolean;
  errorMessage?: string;
  dataCy?: string;
  className?: string;
  smallOptions?: boolean;
  tooltip?: boolean;
  tooltipContent?: ReactNode;
  tooltipPosition?: PlacesType;
  tooltipId?: string;
};

const Select: React.FC<
  InputProps & SelectProps & HTMLProps<HTMLInputElement>
> = ({
  options,
  optionSelected,
  onClickOption,
  title,
  withValidation = false,
  ErrorIconComp = CrossIcon,
  errorMessage,
  id,
  dataCy,
  className = '',
  smallOptions = false,
  tooltip,
  tooltipContent,
  tooltipPosition,
  tooltipId,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);

  useClickOutside(containerRef, () => setShow(false));

  const onClick = (option: SelectType) => {
    setShow(false);
    onClickOption(option);
  };

  const showTitle = () => (
    <div className={classnames('mb-4')}>
      <span
        className={classnames('text__body__semi__bold__medium__textNeutral40')}
      >
        {title}
      </span>
    </div>
  );

  const showOptions = () => (
    <div className={classnames(styles.optionscontent)}>
      {options.map((option) => (
        <button
          key={option.id}
          onClick={() => onClick(option)}
          type="button"
          className={styles.item}
          data-cy={option.value}
        >
          <div
            className={classnames(
              smallOptions
                ? 'text__body__regular__small__textNeutral30'
                : 'text__body__regular__medium__textNeutral30',
              styles.optionTextClass,
              option.value === optionSelected.value
                ? styles.optionSelected
                : '',
            )}
          >
            {option.value}
          </div>
        </button>
      ))}
    </div>
  );

  const showErrorMessage = () => (
    <div className={styles.helperBox}>
      <span
        className={classnames(
          styles.icon,
          errorMessage && errorMessage.length > 1 ? '' : 'invisible',
        )}
      >
        <ErrorIconComp />
      </span>
      <span className="text__body__regular__overline__warning30">
        {errorMessage}
      </span>
    </div>
  );

  return (
    <>
      {title && showTitle()}
      <div
        ref={containerRef}
        className={classnames(styles.selectContainer, className)}
        data-tooltip-id={tooltipId}
      >
        <input
          readOnly
          onClick={() => setShow(!show)}
          id={id}
          className={classnames(styles.regular, {
            [styles.disableInputColor]: !!props.disabled,
          })}
          {...props}
          data-cy={dataCy}
        />
        <ChevronIcon
          onClick={() => setShow(!show)}
          className={classnames(styles.chevron, {
            [styles.chevronRotate]: show,
            [styles.disableChevronColor]: !!props.disabled,
          })}
        />

        {show && showOptions()}
        <Tooltip
          id={tooltipId}
          place={tooltipPosition}
          className={styles.tooltip}
          hidden={!tooltip}
        >
          {tooltipContent}
        </Tooltip>
      </div>
      {withValidation && showErrorMessage()}
    </>
  );
};

export { Select };
