import { UserSignUp } from 'models/user-sign-up';

type SectionsVisited = {
  step1: boolean;
  step2: boolean;
};

type SignUpInitialStateType = {
  step: number;
  couponCode: string;
  subscriptionDeal: SubscriptionDealResponseType[];
  user: UserSignUpType;
  finish: boolean;
  alreadyVisited: SectionsVisited;
  [key: string]: any;
  couponCodeApplied: CouponCodeID[];
};

const signUpinitialState: SignUpInitialStateType = {
  step: 1,
  user: new UserSignUp(),
  subscriptionDeal: [],
  couponCode: '',
  alreadyVisited: { step1: false, step2: false },
  finish: false,
  couponCodeApplied: [],
};

type Action =
  | { type: 'GO_BACK' }
  | { type: 'GO_NEXT' }
  | { type: 'CHANGE_INPUT'; object: string; field: string; value: string }
  | { type: 'COUPON_INPUT'; couponCode?: string }
  | {
      type: 'UPDATE_SUBSCRIPTION_DEALS';
      subscriptionDeals: SubscriptionDealResponseType[];
    }
  | { type: 'STATE_SELECTED'; state: SelectType }
  | { type: 'SECTION_VISITED'; section: string; value: boolean }
  | { type: 'ADD_TOKEN'; token: string }
  | { type: 'REGISTER_SUCCESS' }
  | { type: 'SET_UTM_PROPERTY'; utmProperty: string; utmValue: string }
  | { type: 'COUPON_CODE_APPLIED'; couponCodeId: CouponCodeID[] }
  | { type: 'ADD_TEMPORARY_USER_TOKEN'; temporaryUserToken: string };

const SignUpReducer = (state: SignUpInitialStateType, action: Action) => {
  switch (action.type) {
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GO_NEXT':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'COUPON_INPUT':
      return {
        ...state,
        user: {
          ...state.user,
          couponCode: action.couponCode,
        },
      };
    case 'CHANGE_INPUT':
      return {
        ...state,
        [action.object]: {
          ...state[action.object],
          [action.field]: action.value,
        },
      };
    case 'UPDATE_SUBSCRIPTION_DEALS':
      return {
        ...state,
        subscriptionDeal: action.subscriptionDeals,
      };
    case 'STATE_SELECTED':
      return {
        ...state,
        user: {
          ...state.user,
          state: action.state,
        },
      };
    case 'SECTION_VISITED':
      return {
        ...state,
        alreadyVisited: {
          ...state.alreadyVisited,
          [action.section]: action.value,
        },
      };
    case 'ADD_TOKEN':
      return {
        ...state,
        user: {
          ...state.user,
          token: action.token,
        },
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
      };
    case 'SET_UTM_PROPERTY':
      return {
        ...state,
        user: {
          ...state.user,
          utmProperties: {
            ...state.user.utmProperties,
            [action.utmProperty]: action.utmValue,
          },
        },
      };
    case 'COUPON_CODE_APPLIED':
      return {
        ...state,
        couponCodeApplied: action.couponCodeId,
      };
    case 'ADD_TEMPORARY_USER_TOKEN':
      return {
        ...state,
        user: {
          ...state.user,
          temporaryUserToken: action.temporaryUserToken,
        },
      };

    default:
      return state;
  }
};

export { SignUpReducer, signUpinitialState };
export type { Action };
